import React from 'react';
import { useFirestoreConnect } from 'react-redux-firebase';
import { connect, useSelector } from 'react-redux';

const WithTenant = ({ children }) => {
	useFirestoreConnect(() => [
		{
			collection: 'tenants',
			doc: 'dpplusmrx',
			storeAs: 'tenant',
		},
	]);

	const tenant = useSelector((state) => state.firestore.data.tenant);

	if (!tenant) return null;

	return children;
};

const TenantProvider = (props) => {
	const { uid, children } = props;
	if (!uid) return children;
	else return <WithTenant {...props} />;
};

const mapStateToProps = (state) => ({
	uid: state.firebase.auth.uid,
});

export default connect(mapStateToProps)(TenantProvider);
