import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { GUARD_TYPES, DEFAULT_PATHS } from '../../../config/routes';
import {
	setCurrentRoute,
	setRejectedPath,
} from '../../../redux/actions/routesActions';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';
import { hasRole, ROLES } from 'utils/rolesConstants';
var pathToRegexp = require('path-to-regexp');

// a route needs to be returned containing either a
const WrapperRoute = (props) => <Route data-test='wrapper-route' {...props} />;

export const GuardedRoute = (props) => {
	const {
		uid,
		roles,
		guards,
		component,
		flightObj,
		setCurrentRoute,
		setRejectedPath,
		rejectedPath,
		...rest
	} = props;
	const Component = component;

	if (
		props.guards &&
		props.guards.includes(GUARD_TYPES.CAN_EDIT_FLIGHT) &&
		!flightObj
	)
		return 'Loading...';

	const _passesGuards = () => {
		let _passes = true;
		let _failedGuard = null;
		if (guards) {
			for (let guard of guards) {
				switch (guard) {
					case GUARD_TYPES.WITH_AUTH:
						_passes = !!uid;
						break;
					case GUARD_TYPES.WITHOUT_AUTH:
						_passes = !uid;
						break;
					case GUARD_TYPES.CAN_EDIT_FLIGHT:
						_passes = uid === flightObj.createdBy;
						break;
					case GUARD_TYPES.CAN_REVIEW_FLIGHT:
						_passes = hasRole(roles, ROLES.ADMIN);
						break;
					case GUARD_TYPES.WITH_USER_ROLE:
						_passes = hasRole(roles, ROLES.USER);
						break;
					case GUARD_TYPES.WITHOUT_USER_ROLE:
						_passes = !hasRole(roles, ROLES.USER);
						break;
					default:
						break;
				}
				if (!_passes) {
					_failedGuard = guard;
					break;
				}
			}
		}
		if (_passes) {
			setCurrentRoute({ ...rest });
		} else {
			setRejectedPath(props.path);
		}

		return [_passes, _failedGuard];
	};

	const testResults = _passesGuards();

	const _buildRedirectPath = (sourcePath) => {
		return pathToRegexp.compile(sourcePath)({
			...props.computedMatch.params,
		});
	};

	if (testResults[0]) {
		return (
			<WrapperRoute
				data-test-2='nested-component'
				{...rest}
				render={(rProps) => <Component {...rProps} />}
			/>
		);
	} else {
		// TODO: build contextual re-routing based on flags and rejected paths in redux
		const redirectTo = guards
			? DEFAULT_PATHS.FAILS_GUARD[testResults[1]]
			: null;
		return (
			<WrapperRoute
				data-test-2='nested-redirect'
				{...rest}
				render={() => <Redirect to={_buildRedirectPath(redirectTo)} />}
			/>
		);
	}
};

const mapStateToProps = (state, props) => {
	const obj = {
		uid: state.firebase.auth.uid,
		roles: state.firebase.profile.roles,
	};

	if (props.guards && props.guards.includes(GUARD_TYPES.CAN_EDIT_FLIGHT)) {
		Object.assign(obj, {
			flightObj:
				state.firestore.data[
					`flightForGuard[${props.computedMatch.params.flightId}]`
				],
		});
	}

	return obj;
};

const mapDispatchToProps = (dispatch) => ({
	setCurrentRoute: (route) => dispatch(setCurrentRoute(route)),
	setRejectedPath: (path) => dispatch(setRejectedPath(path)),
});

export default compose(
	firestoreConnect((props) => {
		const queries = [];
		if (props.guards && props.guards.includes(GUARD_TYPES.CAN_EDIT_FLIGHT)) {
			queries.push({
				collection: `/jobs/${props.computedMatch.params.jobId}/flights/`,
				doc: props.computedMatch.params.flightId,
				storeAs: `flightForGuard[${props.computedMatch.params.flightId}]`,
			});
		}
		return queries;
	}),
	connect(mapStateToProps, mapDispatchToProps)
)(GuardedRoute);
