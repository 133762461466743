import { ACTION_TYPES } from '../reducers/algoliaReducer';
import { ALERT_TYPES } from '../reducers/alertsReducer';
import algoliaSearch from 'algoliasearch';

function timeout(ms) {
	// eslint-disable-next-line no-undef
	return new Promise((resolve) => setTimeout(resolve, ms));
}

export function initSearchClient() {
	return async (dispatch, getState, getFirebase) => {
		if (getState().algolia.searchClient) return;
		// set the loading state to true
		dispatch({ type: ACTION_TYPES.SET_LOADING, payload: true });
		// get token from crowd auth via cloud function
		const searchKey = await getFirebase()
			.functions()
			.httpsCallable('algolia-getAlgoliaSearchKey')();

		if (searchKey) {
			const searchClient = algoliaSearch(
				searchKey.data.app_id,
				searchKey.data.search_key
			);
			dispatch({ type: ACTION_TYPES.SET_CLIENT, payload: searchClient });
		} else {
			dispatch({
				type: ALERT_TYPES.ERROR,
				payload: 'Could not initialize search client.',
			});
		}
		dispatch({ type: ACTION_TYPES.SET_LOADING, payload: false });
	};
}

export function searchIndex(idx, query) {
	return async (dispatch, getState, geFirebase) => {
		dispatch({ type: ACTION_TYPES.SET_LOADING, payload: true });
		const client = getState().algolia.searchClient;
		const index = client.initIndex(idx);
		const responses = await index.search(query, {
			hitsPerPage: 6,
		});
		if (responses.hits) {
			dispatch({
				type: ACTION_TYPES.SET_SEARCH_RESULTS,
				payload: { for: idx, result: responses },
			});
		} else {
			dispatch({ type: ALERT_TYPES.WARNING, payload: 'No results found.' });
		}
		await timeout(250);
		dispatch({ type: ACTION_TYPES.SET_LOADING, payload: false });
	};
}

export function paginateResultsTo(idx, target) {
	return async (dispatch, getState, getFirebase) => {
		dispatch({ type: ACTION_TYPES.SET_LOADING, payload: true });
		const client = getState().algolia.searchClient;
		const query = getState().algolia.searchResults[idx].query;
		const index = client.initIndex(idx);
		const responses = await index.search(query, {
			hitsPerPage: 6,
			page: target,
		});
		if (responses.hits) {
			dispatch({
				type: ACTION_TYPES.SET_SEARCH_RESULTS,
				payload: { for: idx, result: responses },
			});
		} else {
			dispatch({ type: ALERT_TYPES.WARNING, payload: 'No results found.' });
		}
		dispatch({ type: ACTION_TYPES.SET_LOADING, payload: false });
	};
}

export function clearResults() {
	return (dispatch) => dispatch({ type: ACTION_TYPES.CLEAR_RESULTS });
}
