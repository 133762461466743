import React, { useEffect, useState } from 'react';
import { Grid, makeStyles, Typography, Paper } from '@material-ui/core';
import FlightForm from '../../organisms/flightForm';
import JobHeader from '../../molecules/jobHeader';
import FlightInfoBar from '../../molecules/flightInfoBar';
import FlightCalloutPanel from '../../molecules/flightCalloutPanel';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';
import FlightActions from '../../molecules/flightActions';
import { FLIGHT_FIELDS_FLAT } from '../../../config/flightDataModel';
import validateForm from '../../../utils/validateForm';
import { updateDocumentsRead } from 'redux/actions/activityActions';

const useStyles = makeStyles((theme) => ({
	topMargin: {
		marginTop: theme.spacing(3),
	},
	paper: {
		padding: theme.spacing(2),
	},
}));

const FlightPage = (props) => {
	const classes = useStyles();
	const { job, flights, updateJobsRead, updateFlightsRead } = props;
	const [flight, setFlight] = useState(null);

	useEffect(() => {
		if (flights && !flight) {
			setFlight(
				flights.find((elem) => elem.id === props.match.params.flightId)
			);
		}
		if (job && job.id && flight && flight.id) {
			updateJobsRead(job.id);
			updateFlightsRead(flight.id);
		}
	}, [
		updateJobsRead,
		updateFlightsRead,
		flight,
		job,
		flights,
		props.match.params.flightId,
	]);

	if (!job || !flight) return 'Loading...';

	return (
		<>
			<JobHeader data={job} />
			<div className={classes.topMargin}>
				<Typography variant='h5'>Flight Information</Typography>
			</div>
			<div className={classes.topMargin}>
				<FlightInfoBar flight={flight} job={job} />
			</div>
			{flight.rejectionNotes && (
				<div className={classes.topMargin}>
					<FlightCalloutPanel {...flight} variant='rejected' />
				</div>
			)}
			{flight.cancellationReason && (
				<div className={classes.topMargin}>
					<FlightCalloutPanel {...flight} variant='cancelled' />
				</div>
			)}
			<Grid container justify='center' className={classes.topMargin}>
				<FlightForm jobObj={job} remoteData={flight} readOnly={true} />
			</Grid>
			<div className={classes.topMargin}>
				<Paper className={classes.paper}>
					<FlightActions
						jobObj={job}
						flightObjArr={[flight]}
						variant='inline'
						validatedIdArr={
							Object.keys(validateForm(flight, FLIGHT_FIELDS_FLAT)).length === 0
								? [flight.id]
								: []
						}
					/>
				</Paper>
			</div>
		</>
	);
};

const mapStateToProps = (
	{ firestore: { data, ordered }, firebase },
	ownProps
) => ({
	job: data[`openJob[${ownProps.match.params.jobId}]`],
	flights: ordered[`openFlights[${ownProps.match.params.jobId}]`],
	uid: firebase.auth.uid,
});

const mapDispatchToProps = (dispatch) => ({
	updateJobsRead: (id) => dispatch(updateDocumentsRead('jobs', id)),
	updateFlightsRead: (id) => dispatch(updateDocumentsRead('flights', id)),
});

export default compose(
	firestoreConnect((ownProps) => [
		{
			collection: `jobs`,
			doc: `${ownProps.match.params.jobId}`,
			storeAs: `openJob[${ownProps.match.params.jobId}]`,
		},
		{
			collection: `jobs/${ownProps.match.params.jobId}/flights`,
			storeAs: `openFlights[${ownProps.match.params.jobId}]`,
		},
		{
			collection: `jobs/${ownProps.match.params.jobId}/flights/${ownProps.match.params.flightId}/history`,
			storeAs: `flightHistory[${ownProps.match.params.flightId}]`,
		},
	]),
	connect(mapStateToProps, mapDispatchToProps)
)(FlightPage);
