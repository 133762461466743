import React, { useState } from 'react';
import { connect } from 'react-redux';
import { IconButton, Badge } from '@material-ui/core';
import NotificationsIcon from '@material-ui/icons/Notifications';
import NotificationFeed from '../NotificationFeed';

const NotificationsBadge = (props) => {
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);

	const handleClick = (event) => {
		if (open) return;
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const { profile } = props;
	const { unreadNotifications } = profile;
	return (
		<IconButton color='inherit' onClick={handleClick}>
			<Badge badgeContent={unreadNotifications || null} color='secondary'>
				<NotificationsIcon />
			</Badge>
			<NotificationFeed
				variant='menu'
				open={open}
				handleClose={handleClose}
				anchorEl={anchorEl}
			/>
		</IconButton>
	);
};

const mapStateToProps = (state) => ({
	profile: state.firebase.profile,
});

export default connect(mapStateToProps)(NotificationsBadge);
