export const ACTION_TYPES = {
	SET_CURRENT_ROUTE: 'ROUTES_SET_CURRENT_ROUTE',
	SET_REJECTED_PATH: 'ROUTES_SET_REJECTED_PATH',
};

const initialState = {
	currentRoute: null,
	rejectedPath: null,
};

export default function routesReducer(state = initialState, action) {
	switch (action.type) {
		case ACTION_TYPES.SET_CURRENT_ROUTE:
			return {
				...state,
				currentRoute: action.payload,
			};
		case ACTION_TYPES.SET_REJECTED_PATH:
			return {
				...state,
				rejectedPath: action.payload,
			};
		default:
			return state;
	}
}
