import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { darken, makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { formatFieldName } from '../../../utils/fieldConverters';
import FlightActions from '../flightActions';
import { InputAdornment } from '@material-ui/core';
import SortIcon from '@material-ui/icons/Sort';
import ArrowDownwardRoundedIcon from '@material-ui/icons/ArrowDownwardRounded';
import ArrowUpwardRoundedIcon from '@material-ui/icons/ArrowUpwardRounded';

const useToolbarStyles = makeStyles((theme) => ({
	root: {
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(1),
	},
	highlight: {
		color: theme.palette.secondary.main,
		backgroundColor: darken(theme.palette.background.paper, 0.1),
	},
	title: {
		flex: '1 1 100%',
		fontWeight: 'bold',
	},
}));

const sortOptions = [
	'statusFlag',
	'channel',
	'parentJob',
	'startDate',
	'endDate',
	'lastUpdateAt',
	'createdAt',
	'budget',
];

const FlightTableToolbar = (props) => {
	const classes = useToolbarStyles();
	const { flightObjArr, count, sortRule, handleSortChange } = props;
	const [numSelected, setNumSelected] = useState(
		flightObjArr ? flightObjArr.length : 0
	);

	useEffect(() => {
		setNumSelected(flightObjArr ? flightObjArr.length : 0);
	}, [setNumSelected, flightObjArr]);

	const handleChange = (e, ruleProperty) => {
		const newRule = {
			...sortRule,
			[ruleProperty]: e.target.value,
		};
		handleSortChange(newRule.field, newRule.direction);
	};

	return (
		<Toolbar
			className={clsx(classes.root, {
				[classes.highlight]: numSelected > 0,
			})}
		>
			<Grid container justify='space-between' align='center'>
				<Grid item>
					{numSelected > 0 ? (
						<Typography
							className={classes.title}
							color='inherit'
							variant='h6'
							component='div'
						>
							{numSelected} Selected
						</Typography>
					) : (
						<Typography
							className={classes.title}
							variant='h6'
							id='tableTitle'
							component='div'
						>
							{count} Flights
						</Typography>
					)}
				</Grid>
				<Grid item>
					{flightObjArr && numSelected > 0 ? (
						<FlightActions {...props} variant='inline' onTable bulk={true} />
					) : (
						<Grid container spacing={2} alignItems='center'>
							<Grid item>
								<Typography style={{ fontWeight: 'bold' }}>Sort By:</Typography>
							</Grid>
							<Grid item>
								<Select
									startAdornment={
										<InputAdornment position='start'>
											<SortIcon />
										</InputAdornment>
									}
									label='Sort By'
									value={sortRule.field}
									onChange={(e) => handleChange(e, 'field')}
								>
									{sortOptions.map((h, idx) => (
										<MenuItem value={h} key={idx}>
											{formatFieldName(h)}
										</MenuItem>
									))}
								</Select>
							</Grid>
							<Grid item>
								<Select
									value={sortRule.direction}
									label='Sort Direction'
									onChange={(e) => handleChange(e, 'direction')}
									startAdornment={
										<InputAdornment>
											{sortRule.direction === 'asc' ? (
												<ArrowUpwardRoundedIcon />
											) : (
												<ArrowDownwardRoundedIcon />
											)}
										</InputAdornment>
									}
								>
									<MenuItem value='asc'>Ascending</MenuItem>
									<MenuItem value='desc'>Descending</MenuItem>
								</Select>
							</Grid>
						</Grid>
					)}
				</Grid>
			</Grid>
		</Toolbar>
	);
};

export default FlightTableToolbar;
