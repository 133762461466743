import React, { useState } from 'react';
import {
	Typography,
	IconButton,
	Menu,
	MenuItem,
	ListItemIcon,
	Grid,
	Button,
	makeStyles,
} from '@material-ui/core';
import ConfirmationDialog from '../confirmationDialog';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import SendIcon from '@material-ui/icons/Send';
import RateReviewIcon from '@material-ui/icons/RateReview';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ReplayIcon from '@material-ui/icons/Replay';
import GetAppIcon from '@material-ui/icons/GetApp';
import BlockIcon from '@material-ui/icons/Block';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import {
	crossJobTransition,
	exportFlightsCsv,
} from '../../../redux/actions/mediaBuyActions.ts';
import { FLIGHT_STATUS } from 'utils/flightConstants';
import { ROLES, hasRole } from 'utils/rolesConstants';

const cloneDeep = require('lodash.clonedeep');

const MenuVariant = (props) => {
	const { allowedActions, bulk } = props;
	const [anchorElem, setAnchorElem] = useState(null);

	const handleClick = (e) => setAnchorElem(e.currentTarget);
	const handleClose = () => setAnchorElem(null);

	const closeAndHandle = (callBack) => {
		handleClose();
		callBack();
	};

	return (
		<div>
			<IconButton
				aria-label='actions'
				aria-haspopup='true'
				onClick={handleClick}
			>
				<MoreVertIcon />
			</IconButton>
			<Menu
				anchorEl={anchorElem}
				open={Boolean(anchorElem)}
				onClose={handleClose}
			>
				{allowedActions.map((A) => (
					<MenuItem key={A.key} onClick={() => closeAndHandle(A.callback)}>
						<ListItemIcon>
							<A.icon />
						</ListItemIcon>
						<Typography variant='inherit'>{`${A.label}${
							bulk ? ' Flights' : ' Flight'
						}${
							A.bookendLabel
								? bulk
									? A.bookendLabel + 's'
									: A.bookendLabel
								: ''
						}`}</Typography>
					</MenuItem>
				))}
			</Menu>
		</div>
	);
};

const useStyles = makeStyles((theme) => ({
	inlineIcon: {
		marginRight: theme.spacing(1),
	},
}));

const InlineVariant = (props) => {
	const { allowedActions, bulk } = props;
	const classes = useStyles();

	return (
		<Grid container spacing={2}>
			{allowedActions.map((A) => (
				<Grid key={A.key} item>
					<Button
						onClick={A.callback}
						variant='contained'
						color={A.color || 'secondary'}
					>
						<A.icon className={classes.inlineIcon} />
						{`${A.label}${bulk ? ' Flights' : ' Flight'}${
							A.bookendLabel
								? bulk
									? A.bookendLabel + 's'
									: A.bookendLabel
								: ''
						}`}
					</Button>
				</Grid>
			))}
		</Grid>
	);
};

const FlightActions = (props) => {
	const { variant, validatedIdArr, profile, uid, bulk, onTable } = props;
	let { flightObj, flightObjArr } = props;
	if (flightObj && !flightObjArr) flightObjArr = [flightObj];
	if (flightObjArr && !flightObj) flightObj = flightObjArr[0];

	const [confirmAction, setConfirmAction] = useState(null);
	const [confirmationText, setConfirmationText] = useState(null);
	const [confirmationContent, setConfirmationContent] = useState(null);
	const [confirmationInput, setConfirmationInput] = useState(null);

	const history = useHistory();

	const ACTIONS = {
		EDIT: {
			key: '_EDIT',
			label: 'Edit',
			icon: EditIcon,
			noBulk: true,
			callback: () =>
				history.push(
					`/jobs/${flightObj.parentJob}/flight/${flightObj.id}/edit`
				),
		},
		SUBMIT: {
			key: '_SUBMIT',
			label: 'Submit',
			icon: SendIcon,
			color: 'secondary',
			callback: () => props.submitFlights(flightObjArr),
		},
		DUPLICATE: {
			key: '_DUPLICATE',
			label: 'Duplicate',
			noBulk: true,
			icon: FileCopyIcon,
			callback: () =>
				history.push(
					`/jobs/${flightObj.parentJob}/new-flight?clone=${flightObj.id}`
				),
		},
		DELETE: {
			key: '_DELETE',
			label: 'Delete',
			icon: DeleteIcon,
			color: 'secondary',
			callback: () =>
				props.deleteFlights(flightObjArr).then(() => {
					history.push(`/jobs/${flightObj.parentJob}`);
				}),
			confirm: {
				text: 'DELETE FLIGHT',
				bulkText: 'DELETE FLIGHTS',
				content: 'Are you sure you want to delete this flight?',
				bulkContent: 'Are you sure you want to delete these flights?',
			},
		},
		CANCEL: {
			key: '_CANCEL',
			label: 'Cancel',
			icon: BlockIcon,
			color: 'secondary',
			callback: () =>
				props.cancelFlights(flightObjArr).then(() => {
					if (flightObjArr.length === 1) {
						history.push(`/jobs/${flightObj.parentJob}`);
					}
				}),
			confirm: {
				text: 'CANCEL FLIGHT',
				content: 'Are you sure you want to cancel this flight?',
				bulkText: 'CANCEL FLIGHTS',
				bulkContent: 'Are you sure you want to cancel these flights?',
				input: {
					id: 'cancellationReason',
					multiline: true,
					label: 'Cancellation Reason',
					required: true,
				},
			},
		},
		REVIEW: {
			key: '_REVIEW',
			label: 'Review',
			icon: RateReviewIcon,
			callback: () =>
				props.reviewFlights(flightObjArr).then(() => {
					if (flightObjArr.length === 1) {
						history.push(
							`/jobs/${flightObj.parentJob}/flight/${flightObj.id}/review`
						);
					}
				}),
		},
		REJECT: {
			key: '_REJECT',
			label: 'Reject',
			icon: ThumbDownIcon,
			color: 'secondary',
			callback: () =>
				props.rejectFlights(flightObjArr).then(() => {
					if (flightObjArr.length === 1) {
						history.push(`/jobs/${flightObj.parentJob}`);
					}
				}),
			confirm: {
				text: 'REJECT FLIGHT',
				content: 'Are you sure you want to reject this flight?',
				bulkText: 'REJECT FLIGHTS',
				bulkContent: 'Are you sure you want to reject these flights?',
				input: {
					id: 'rejectionNotes',
					multiline: true,
					label: 'Rejection Notes',
					required: true,
				},
			},
		},
		APPROVE: {
			key: '_APPROVE',
			label: 'Approve',
			icon: ThumbUpIcon,
			callback: () => props.approveFlights(flightObjArr),
		},
		VIEW_REVIEW: {
			key: '_VIEW_REVIEW',
			label: 'View Review of',
			icon: VisibilityIcon,
			noBulk: true,
			callback: () =>
				history.push(
					`/jobs/${flightObj.parentJob}/flight/${flightObj.id}/review`
				),
		},
		EDIT_AS_DRAFT: {
			key: '_EDIT_AS_DRAFT',
			label: 'Edit',
			bookendLabel: ' as Draft',
			icon: EditIcon,
			callback: () =>
				props.moveToDrafts(flightObjArr).then(() => {
					if (flightObjArr.length === 1) {
						history.push(
							`/jobs/${flightObj.parentJob}/flight/${flightObj.id}/edit`
						);
					}
				}),
		},
		UNSUBMIT: {
			key: '_UNSUBMIT',
			label: 'Unsubmit',
			icon: ReplayIcon,
			callback: () => props.unsubmitFlights(flightObjArr),
		},
		EXPORT: {
			key: '_EXPORT',
			label: 'Export',
			icon: GetAppIcon,
			callback: () => props.exportFlights(flightObjArr),
		},
	};

	const _generateAllowedActions = (flight) => {
		const allowedActions = [];
		const isAdmin = hasRole(profile.roles, ROLES.ADMIN);
		const isOwned = flight.createdBy === uid;
		const isValid = validatedIdArr.includes(flight.id);
		allowedActions.push(ACTIONS.DUPLICATE);
		switch (flight.statusFlag) {
			case FLIGHT_STATUS.REJECTED.dbKey:
				if (isOwned) allowedActions.push(ACTIONS.EDIT_AS_DRAFT);
				break;
			case FLIGHT_STATUS.DRAFT.dbKey:
				if (isOwned) allowedActions.push(ACTIONS.EDIT);
				if (isOwned) allowedActions.push(ACTIONS.DELETE);
				if (isValid && isOwned) allowedActions.push(ACTIONS.SUBMIT);
				break;
			case FLIGHT_STATUS.SUBMITTED.dbKey:
				if (isAdmin) allowedActions.push(ACTIONS.REVIEW);
				if (isOwned) allowedActions.push(ACTIONS.UNSUBMIT);
				break;
			case FLIGHT_STATUS.IN_REVIEW.dbKey:
				if (isAdmin) allowedActions.push(ACTIONS.VIEW_REVIEW);
				if (isAdmin) allowedActions.push(ACTIONS.APPROVE);
				if (isAdmin && variant === 'inline' && onTable)
					allowedActions.push(ACTIONS.REJECT);
				break;
			case FLIGHT_STATUS.APPROVED.dbKey:
				allowedActions.push(ACTIONS.CANCEL);
				break;
			default:
				break;
		}
		allowedActions.push(ACTIONS.EXPORT);
		const wrappedActions = cloneDeep(allowedActions);
		wrappedActions.forEach((a) => {
			if (a.confirm) {
				a.callback = () => {
					setConfirmAction(
						Object.values(ACTIONS).find(({ key }) => key === a.key)
					);
					setConfirmationText(
						flightObjArr.length < 2 ? a.confirm.text : a.confirm.bulkText
					);
					setConfirmationContent(
						flightObjArr.length < 2 ? a.confirm.content : a.confirm.bulkContent
					);
					setConfirmationInput(a.confirm.input);
				};
			}
		});
		return wrappedActions;
	};

	const getBulkAllowed = () => {
		const allowedPerFlight = flightObjArr.map((flight) => {
			const actions = _generateAllowedActions(flight);
			if (bulk || flightObjArr.length > 1)
				return actions.filter(({ noBulk }) => !noBulk);
			else return actions;
		});
		if (allowedPerFlight.length === 0) return [];
		if (allowedPerFlight.length === 1) return allowedPerFlight[0];
		return allowedPerFlight[0].filter((subAllowed) => {
			let otherAllowedCount = allowedPerFlight.length;
			for (let i = 1; i < otherAllowedCount; i++) {
				if (!allowedPerFlight[i].map((a) => a.key).includes(subAllowed.key)) {
					return false;
				}
			}
			return true;
		});
	};

	const clearConfirm = () => {
		setConfirmAction(null);
		setConfirmationText(null);
		setConfirmationContent(null);
	};

	const confirm = () => {
		confirmAction.callback();
		clearConfirm();
	};

	const cancel = () => clearConfirm();

	let Variant;

	if (variant === 'menu') Variant = MenuVariant;
	if (variant === 'inline') Variant = InlineVariant;

	return (
		<>
			<Variant {...props} allowedActions={getBulkAllowed()} />
			<ConfirmationDialog
				open={!!confirmAction}
				confirmCallback={confirm}
				confirmText={confirmationText}
				confirmInput={confirmationInput}
				content={confirmationContent}
				cancelCallback={cancel}
				flightObjArr={flightObjArr}
			/>
		</>
	);
};

const mapStateToProps = (state) => ({
	profile: state.firebase.profile,
	uid: state.firebase.auth.uid,
});

const mapDispatchToProps = (dispatch) => ({
	submitFlights: (flightObjArr) =>
		dispatch(crossJobTransition(flightObjArr, FLIGHT_STATUS.SUBMITTED.dbKey)),
	unsubmitFlights: (flightObjArr) =>
		dispatch(
			crossJobTransition(flightObjArr, FLIGHT_STATUS.DRAFT.dbKey, {
				unsubmit: true,
			})
		),
	reviewFlights: (flightObjArr) =>
		dispatch(crossJobTransition(flightObjArr, FLIGHT_STATUS.IN_REVIEW.dbKey)),
	moveToDrafts: (flightObjArr) =>
		dispatch(crossJobTransition(flightObjArr, FLIGHT_STATUS.DRAFT.dbKey)),
	deleteFlights: (flightObjArr) =>
		dispatch(crossJobTransition(flightObjArr, FLIGHT_STATUS.DELETED.dbKey)),
	cancelFlights: (flightObjArr) =>
		dispatch(crossJobTransition(flightObjArr, FLIGHT_STATUS.CANCELLED.dbKey)),
	approveFlights: (flightObjArr) =>
		dispatch(crossJobTransition(flightObjArr, FLIGHT_STATUS.APPROVED.dbKey)),
	rejectFlights: (flightObjArr) =>
		dispatch(crossJobTransition(flightObjArr, FLIGHT_STATUS.REJECTED.dbKey)),
	exportFlights: (flightObjArr) => dispatch(exportFlightsCsv(flightObjArr)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FlightActions);
