import React from 'react';
import { Paper, Typography, makeStyles, Grid } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(2),
	},
	gridItem: {
		padding: theme.spacing(2),
	},
}));

const RejectedVariant = ({ rejectionNotes, rejectedFields }) => (
	<Grid container justify='space-between'>
		<Grid item>
			<Typography>
				<b>Rejection Notes:</b> {rejectionNotes}
			</Typography>
		</Grid>
		{rejectedFields && rejectedFields.length > 0 && (
			<Grid item>
				<Typography color='error'>Rejected fields shown in red.</Typography>
			</Grid>
		)}
	</Grid>
);

const CancelledVariant = ({ cancellationReason }) => (
	<Grid container justify='left'>
		<Grid item>
			<Typography>
				<b>Cancellation Reason:</b> {cancellationReason}
			</Typography>
		</Grid>
	</Grid>
);

const FlightCalloutPanel = (props) => {
	const classes = useStyles();
	const { variant } = props;

	let Component;
	switch (variant) {
		case 'rejected':
			Component = (
				<RejectedVariant
					rejectedFields={props.rejectedFields}
					rejectionNotes={props.rejectionNotes}
				/>
			);
			break;
		case 'cancelled':
			Component = (
				<CancelledVariant cancellationReason={props.cancellationReason} />
			);
			break;
		default:
			Component = null;
			break;
	}

	return <Paper className={classes.root}>{Component}</Paper>;
};

export default FlightCalloutPanel;
