import React from 'react';
import { connect } from 'react-redux';
import {
	AppBar,
	Toolbar,
	Typography,
	IconButton,
	makeStyles,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import clsx from 'clsx';
import NotificationsMenu from '../NotificationsBadge';

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	menuButton: {
		marginRight: theme.spacing(2),
	},
	title: {
		flexGrow: 1,
	},
}));

// anything passed to this custom NavBar will be inherited by the root AppBar
export function NavBar(props) {
	const { title, isUser, drawerOpen, setDrawerOpen, currentRoute } = props;
	let displayTitle = currentRoute ? currentRoute.title : title;
	const classes = props.useStyles();
	const localClasses = useStyles();

	const handleMenuClick = (event) => {
		event.preventDefault();
		setDrawerOpen(!drawerOpen);
	};

	return (
		<div className={localClasses.root}>
			<AppBar
				position='fixed'
				className={clsx(classes.appBar, { [classes.appBarShift]: drawerOpen })}
				data-test='root'
			>
				<Toolbar>
					{isUser && (
						<IconButton
							data-test='menu-button'
							edge='start'
							color='inherit'
							aria-label='menu'
							onClick={handleMenuClick}
							className={clsx(classes.menuButton, {
								[classes.hide]: drawerOpen && false,
							})}
						>
							<MenuIcon />
						</IconButton>
					)}
					<Typography
						className={localClasses.title}
						variant='h5'
						color='inherit'
						data-test='rendered-title'
					>
						{displayTitle}
					</Typography>
					{isUser && (
						<div className={localClasses.menuButton}>
							<NotificationsMenu />
						</div>
					)}
				</Toolbar>
			</AppBar>
		</div>
	);
}

const mapStateToProps = (state) => ({
	uid: state.firebase.auth.uid,
	currentRoute: state.routes.currentRoute,
});

export default connect(mapStateToProps)(NavBar);
