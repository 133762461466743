import {
	ThumbDown,
	ThumbUp,
	Edit,
	Send,
	RateReview,
	Block,
	DeleteOutline,
} from '@material-ui/icons';

export const FLIGHT_STATUS = {
	REJECTED: {
		dbKey: 'rejected',
		display: 'Rejected',
		color: '#ff8a65',
		icon: ThumbDown,
		sortOrder: 5,
	},
	DRAFT: {
		dbKey: 'draft',
		display: 'Draft',
		color: '#ffb74d',
		icon: Edit,
		sortOrder: 10,
	},
	SUBMITTED: {
		dbKey: 'submitted',
		display: 'Submitted',
		color: '#dce775',
		icon: Send,
		sortOrder: 15,
	},
	IN_REVIEW: {
		dbKey: 'review',
		display: 'In Review',
		color: '#90a4ae',
		icon: RateReview,
		sortOrder: 20,
	},
	APPROVED: {
		dbKey: 'approved',
		display: 'Approved',
		color: '#7986cb',
		icon: ThumbUp,
		sortOrder: 25,
	},
	CANCELLED: {
		dbKey: 'cancelled',
		display: 'Cancelled',
		color: '#e0e0e0',
		icon: Block,
		sortOrder: 30,
	},
	DELETED: {
		dbKey: 'deleted',
		display: 'Deleted',
		color: '#a1887f',
		icon: DeleteOutline,
		sortOrder: 90,
	},
};

// flights with these statuses shouldn't appear in the UI
export const HIDDEN_STATUSES = [FLIGHT_STATUS.DELETED];

export const colorFromDbKey = (key) =>
	Object.values(FLIGHT_STATUS).find(({ dbKey }) => dbKey === key).color;

export const statusObjFromKey = (key) =>
	Object.values(FLIGHT_STATUS).find(({ dbKey }) => dbKey === key);

export const sortOrderFromDbKey = (key) =>
	Object.values(FLIGHT_STATUS).find(({ dbKey }) => dbKey === key).sortOrder;

export const displayFromDbKey = (key) =>
	Object.values(FLIGHT_STATUS).find(({ dbKey }) => dbKey === key).display;

export const iconFromDbKey = (key) =>
	Object.values(FLIGHT_STATUS).find(({ dbKey }) => dbKey === key).icon;

export const dbKeyArray = Object.values(FLIGHT_STATUS).map(
	({ dbKey }) => dbKey
);

// only the keys for the statuses that should be visible in the UI
export const visibleDbKeyArray = dbKeyArray.filter(
	(i) => !HIDDEN_STATUSES.map((v) => v.dbKey).includes(i)
);

export const getColorsObject = () => {
	let obj = {};
	Object.keys(FLIGHT_STATUS).forEach((key) => {
		obj[key] = FLIGHT_STATUS[key].color;
	});
	return obj;
};

export const dbKeysInOrder = [
	FLIGHT_STATUS.REJECTED.dbKey,
	FLIGHT_STATUS.DRAFT.dbKey,
	FLIGHT_STATUS.SUBMITTED.dbKey,
	FLIGHT_STATUS.IN_REVIEW.dbKey,
	FLIGHT_STATUS.APPROVED.dbKey,
	FLIGHT_STATUS.CANCELLED.dbKey,
	FLIGHT_STATUS.DELETED.dbKey,
];
