import React from 'react';
import { ReactComponent as Icon } from './sign-in-with-microsoft.svg';
import { makeStyles, Backdrop, CircularProgress } from '@material-ui/core';
import { connect } from 'react-redux';
import { signInWithMicrosoft } from 'redux/actions/authActions';

const useStyles = makeStyles({
	wrapper: {
		cursor: 'pointer',
	},
	backDrop: {
		zIndex: 1000,
	},
});

const SignInWithMicrosoft = (props) => {
	const classes = useStyles();
	const { signIn, loading } = props;
	return (
		<>
			<Backdrop className={classes.backDrop} open={loading}>
				<CircularProgress color='primary' />
			</Backdrop>
			<div className={classes.wrapper} onClick={signIn}>
				<Icon />
			</div>
		</>
	);
};

const mapStateToProps = (state) => ({
	loading: state.auth.loading,
});

const mapDispatchToProps = (dispatch) => ({
	signIn: () => dispatch(signInWithMicrosoft()),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SignInWithMicrosoft);
