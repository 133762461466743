/* eslint-disable no-case-declarations */
import { ACTION_TYPES as ALERT_ACTIONS } from './alertsReducer';

export const ACTION_TYPES = {
	...ALERT_ACTIONS,
	ADD_ITEMS: 'NOTIFICATION_ADD_ITEMS',
	RESET: 'NOTIFICATION_CLEAR_ALL',
	SET_LOADING: 'NOTIFICATION_SET_LOADING',
	SET_SHOULD_RESET: 'NOTIFICATION_SET_SHOULD_RESET',
	MARK_IDS_READ: 'NOTIFICATION_MARK_IDS_READ',
};

const initialState = {
	loading: false,
	shouldReset: false,
	listNotifications: [],
	noMore: false,
};

export default function NotificationReducer(state = initialState, action) {
	switch (action.type) {
		case ACTION_TYPES.MARK_IDS_READ:
			let updated = [...state.listNotifications];
			updated.forEach((n) => {
				if (action.payload.includes(n.id)) {
					n.read = true;
				}
			});
			return {
				...state,
				listNotifications: updated,
			};
		case ACTION_TYPES.SET_SHOULD_RESET:
			return {
				...state,
				shouldReset: action.payload,
			};
		case ACTION_TYPES.SET_LOADING:
			return {
				...state,
				loading: action.payload,
			};
		case ACTION_TYPES.ADD_ITEMS:
			let newList = state.listNotifications.concat(action.payload.newItems);
			return {
				...state,
				listNotifications: newList,
				noMore: action.payload.noMore,
			};
		case ACTION_TYPES.RESET:
			return initialState;
		default:
			return state;
	}
}
