import React from 'react';
import { BrowserRouter as Router, Switch, Redirect } from 'react-router-dom';

import GuardedRoute from './components/hocs/guardedRoute';
import { FLAT_ROUTES, DEFAULT_PATHS } from './config/routes';

import NavWrapper from './components/hocs/navWrapper';

import { isLoaded } from 'react-redux-firebase';
import { useSelector } from 'react-redux';

// force browser to scroll to the top of the page when the route changes
import ScrollToTop from './components/utility/scrollToTop';

import getTheme from 'utils/theme';
import { ThemeProvider } from '@material-ui/core/styles';
import SyncVersion from 'components/utility/SyncVersion';
import ReadNotification from 'components/utility/ReadNotification';
import { useMediaQuery, CssBaseline } from '@material-ui/core';
import TenantProvider from 'components/utility/TenantProvider';

const GUARDED_ROUTES = Object.values(FLAT_ROUTES).map((DATA, idx) => (
	<GuardedRoute key={idx} {...DATA} />
));

function App() {
	const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
	const auth = useSelector((state) => state.firebase.auth);
	const profile = useSelector((state) => state.firebase.profile);

	const isReady = () => {
		return isLoaded(auth) && isLoaded(profile);
	};

	return (
		<div data-test='app-root'>
			<ThemeProvider theme={getTheme(prefersDarkMode)}>
				<CssBaseline />
				{isReady() ? (
					<Router data-test='rendered-router'>
						<SyncVersion>
							<TenantProvider>
								<ScrollToTop />
								<ReadNotification />
								<NavWrapper data-test='rendered-navwrapper'>
									<Switch data-test='rendered-switch'>
										{GUARDED_ROUTES}
										<Redirect exact from='/' to={DEFAULT_PATHS.ROOT_PAGE} />
										<Redirect to={DEFAULT_PATHS.PAGE_NOT_FOUND} />
									</Switch>
								</NavWrapper>
							</TenantProvider>
						</SyncVersion>
					</Router>
				) : null}
			</ThemeProvider>
		</div>
	);
}

export default App;
