import React from 'react';
import { Grid } from '@material-ui/core';
import JobForm from '../../organisms/jobForm';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';

export const EditJobPage = (props) => {
	const { job } = props;

	if (!job) return 'Loading...';

	return (
		<div data-test='root'>
			<Grid container justify='center'>
				<Grid item>
					<JobForm data-test='media-buy-form' remoteData={job} />
				</Grid>
			</Grid>
		</div>
	);
};

const mapStateToProps = ({ firestore: { data, ordered } }, ownProps) => ({
	job: data[`openJob[${ownProps.match.params.jobId}]`],
});

export default compose(
	firestoreConnect((ownProps) => [
		{
			collection: `jobs`,
			doc: `${ownProps.match.params.jobId}`,
			storeAs: `openJob[${ownProps.match.params.jobId}]`,
		},
	]),
	connect(mapStateToProps)
)(EditJobPage);
