import { ACTION_TYPES } from '../reducers/versionSyncReducer';
import { ALERT_TYPES } from '../reducers/alertsReducer';

export function setNewVersionAlert(payload) {
	return { type: ACTION_TYPES.SET_ALERT, payload };
}

export function newVersionAvailable(build, semVer) {
	return (dispatch) => {
		dispatch(
			setNewVersionAlert({
				type: ALERT_TYPES.INFO,
				message: `Flight Check ${semVer} (${build}) is now available. Please save your work and refresh your browser window.`,
				noAutoHide: true,
			})
		);
		dispatch({
			type: ACTION_TYPES.SET_NEW_VERSION,
			payload: {
				newVersionAvailable: true,
				newVersionBuild: build,
				newVersionSemVer: semVer,
			},
		});
	};
}
