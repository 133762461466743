import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';
import { toInteger } from 'lodash';
import { newVersionAvailable } from 'redux/actions/versionSyncActions';
import { Button } from '@material-ui/core';

const getLocalBuild = () => {
	// eslint-disable-next-line
	if (window._env_.ENVIRONMENT === '${environment}') {
		return 0;
	} else return toInteger(window._env_.BUILD);
};

const SyncVersion = ({ children, configVersion, triggerNewVersionAlert }) => {
	let { build, semVer, onDeployRan, forceMaintenance } = configVersion || {};
	if (build) build = toInteger(build);

	const [localBuild] = useState(getLocalBuild());
	const [allowedIn, setAllowedIn] = useState(false);
	const [initalCheckComplete, setInitialCheckComplete] = useState(false);

	useEffect(() => {
		if (localBuild === 0) {
			console.log('No local build number in dev mode. Skipping sync.');
			setAllowedIn(true);
			setInitialCheckComplete(true);
			return;
		}
		if (!build) return;
		console.log('Local Build: ', localBuild);
		console.log('Remote Build: ', build);
		if (forceMaintenance) {
			console.log('Maintenance mode forced');
			setAllowedIn(false);
			return;
		}
		if (!onDeployRan) {
			console.log('Maintenance Scripts are currently running.');
			return;
		}
		if (!initalCheckComplete && !!build) {
			if (localBuild === build) {
				setAllowedIn(true);
			}
			setInitialCheckComplete(true);
		}
		if (initalCheckComplete && !!localBuild) {
			if (!allowedIn && build === localBuild) setAllowedIn(true);
			if (allowedIn && build > localBuild && onDeployRan) {
				triggerNewVersionAlert(build, semVer);
			}
		}
	}, [
		build,
		initalCheckComplete,
		localBuild,
		semVer,
		allowedIn,
		triggerNewVersionAlert,
		onDeployRan,
		forceMaintenance,
	]);

	if (!allowedIn) {
		if (!build) return 'Checking Version...';
		else if (localBuild > build || !onDeployRan || forceMaintenance) {
			return 'Updates are being deployed. Please wait...';
		} else if (localBuild < build) {
			return (
				<div style={{ margin: '20px' }}>
					<Button
						color='primary'
						variant='contained'
						onClick={() => window.location.reload(true)}
					>
						Reload
					</Button>
					<span style={{ marginLeft: '20px' }}>
						There is a new version available.
					</span>
				</div>
			);
		} else return 'Unknown build mismatch error. Please try again later.';
	}

	return <>{children}</>;
};

const mapStateToProps = (state) => ({
	configVersion: state.firestore.data.configVersion,
});

const mapDispatchToProps = (dispatch) => ({
	triggerNewVersionAlert: (build, semVer) =>
		dispatch(newVersionAvailable(build, semVer)),
});

export default compose(
	firestoreConnect(() => [
		{
			collection: 'config',
			doc: 'version',
			storeAs: 'configVersion',
		},
	]),
	connect(mapStateToProps, mapDispatchToProps)
)(SyncVersion);
