export const ROLES = {
	NONE: {
		strength: 0,
		display: 'None',
	},
	USER: {
		dbKey: 'user',
		display: 'User',
		strength: 1,
	},
	ADMIN: {
		dbKey: 'admin',
		display: 'Admin',
		strength: 2,
	},
	SUPER_ADMIN: {
		dbKey: 'super-admin',
		display: 'System Admin',
		strength: 3,
	},
};

// return an array for database storage of all dbKeys of roles at or below the strength of the current role
export const getDbRoleArray = (role) => {
	return Object.values(ROLES)
		.filter((obj) => obj.dbKey && obj.strength <= role.strength)
		.map((obj) => obj.dbKey);
};

export const roleFromDbKey = (getDbKey) =>
	Object.values(ROLES).find(({ dbKey }) => dbKey === getDbKey);

export const getRoleFromStrength = (getStrength) =>
	Object.values(ROLES).find(({ strength }) => getStrength === strength);

export const displayRoleFromDb = (dbKey) => roleFromDbKey(dbKey).display;

export const hasRole = (roles, checkRole) => {
	if (!roles) return false;
	return roles.includes(checkRole.dbKey);
};

export const getHighestRole = (DbKeyArray) => {
	const roles = DbKeyArray.map((key) => roleFromDbKey(key));
	let highestRole = ROLES.NONE;
	roles.forEach((role) => {
		if (role && role.strength > highestRole.strength) highestRole = role;
	});
	return highestRole;
};
