import { createMuiTheme } from '@material-ui/core/styles';
import { getColorsObject } from 'utils/flightConstants';

const getTheme = (dark) => {
	return createMuiTheme({
		palette: {
			type: dark ? 'dark' : 'light',
			primary: {
				main: '#1e88e5',
			},
			flights: {
				...getColorsObject(),
			},
		},
	});
};

export default getTheme;
