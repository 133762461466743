import { ACTION_TYPES } from '../reducers/flightTableReducer';
import { ExtendedFirestoreInstance } from 'react-redux-firebase';

export const getQueryId = (filterRules, sortRules, perPage, jobId = null) => {
	let id = jobId || 'allFlights';
	id += '-';
	id += perPage.toString();
	id += '-';
	sortRules.forEach(({ field, direction }) => {
		id += `${field}-${direction}-`;
	});
	filterRules.forEach(({ field, matches, type }) => {
		id += `${field}-${type}-${matches.join()}-`;
	});
	return id;
};

const filterFlights = (flights, filterRules) => {
	let filteredFlights = [...flights];
	filterRules.forEach(({ field, matches, type }) => {
		filteredFlights = filteredFlights.filter((flight) => {
			switch (type) {
				case 'include':
					return matches.includes(flight[field]);
				case 'exclude':
					return !matches.includes(flight[field]);
				default:
					return true;
			}
		});
	});
	return filteredFlights;
};

const sortRuleToOrderBy = ({ field, direction }) => {
	if (field === 'statusFlag') {
		return ['statusFlagSort', direction];
	} else {
		return [field, direction];
	}
};

const buildQuery = (
	db: ExtendedFirestoreInstance,
	sortRules,
	perPage,
	lastVisible: any = null,
	jobId = null
) => {
	let query = jobId
		? db.collection(`jobs/${jobId}/flights`)
		: db.collectionGroup('flights');
	sortRules.forEach((rule) => {
		const converted = sortRuleToOrderBy(rule);
		query = query.orderBy(converted[0], converted[1]);
	});
	query = query.limit(perPage);
	if (lastVisible !== null) {
		query = query.startAfter(lastVisible);
	}
	return query;
};

const getLastVisible = async (
	dispatch,
	getState,
	getFirebase,
	args,
	queryId
) => {
	const { pageNumber } = args;
	const state = getState();
	if (state.flightTables[queryId]) {
		if (state.flightTables[queryId][pageNumber - 1]) {
			return state.flightTables[queryId][pageNumber - 1].lastVisible;
		} else {
			const newArgs = {
				...args,
				pageNumber: pageNumber - 1,
				clearOtherPages: false,
			};
			await _getFlightsInternal(dispatch, getState, getFirebase, newArgs);
			return getState().flightTables[queryId][pageNumber - 1].lastVisible;
		}
	}
};

const _getFlightsInternal = async (dispatch, getState, getFirebase, args) => {
	const {
		filterRules,
		sortRules,
		perPage,
		pageNumber,
		clearOtherPages,
		jobId,
	} = args;
	const db: ExtendedFirestoreInstance = getFirebase().firestore();
	const queryId = getQueryId(filterRules, sortRules, perPage, jobId);
	let prevLastVisible = null;
	if (pageNumber > 0) {
		if (clearOtherPages)
			dispatch({
				type: ACTION_TYPES.CLEAR_OTHER_PAGES,
				payload: { queryId, currentPage: pageNumber },
			});
		prevLastVisible = await getLastVisible(
			dispatch,
			getState,
			getFirebase,
			args,
			queryId
		);
	}
	const query = buildQuery(db, sortRules, perPage, prevLastVisible, jobId);
	const snapshot = await query.get();
	let lastVisible = snapshot.docs[snapshot.docs.length - 1];
	const flights = snapshot.docs.map((doc) => doc.data());
	let filtered = filterFlights(flights, filterRules);
	while (filtered.length < perPage) {
		const remainder = perPage - filtered.length;
		const newQuery = buildQuery(db, sortRules, remainder, lastVisible, jobId);
		const newSnapshot = await newQuery.get();
		if (newSnapshot.docs.length < 1) break;
		lastVisible = newSnapshot.docs[newSnapshot.docs.length - 1];
		const newFlights = newSnapshot.docs.map((doc) => doc.data());
		filtered = [...filtered, ...filterFlights(newFlights, filterRules)];
	}
	dispatch({
		type: ACTION_TYPES.SET_FLIGHTS,
		payload: {
			flights: filtered,
			queryId,
			pageNumber,
			lastVisible,
		},
	});
};

export const getFlights = (args) => {
	return async (dispatch, getState, getFirebase) => {
		if (getState().flightTables.loading) return;
		dispatch({ type: ACTION_TYPES.SET_LOADING, payload: true });
		await _getFlightsInternal(dispatch, getState, getFirebase, args);
		dispatch({ type: ACTION_TYPES.SET_LOADING, payload: false });
	};
};
