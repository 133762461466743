import React, { useState } from 'react';
import Navigation from '../../organisms/navigation';
import { makeStyles, Box } from '@material-ui/core';
import clsx from 'clsx';

const drawerWidth = 240;

export const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
	},
	appBarShift: {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	menuButton: {
		marginRight: 36,
	},
	hide: {
		display: 'none',
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
		whiteSpace: 'nowrap',
	},
	drawerOpen: {
		width: drawerWidth,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	drawerClose: {
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		overflowX: 'hidden',
		width: theme.spacing(7) + 1,
		[theme.breakpoints.up('sm')]: {
			width: theme.spacing(9) + 1,
		},
		[theme.breakpoints.down('sm')]: {
			width: 0,
		},
	},
	toolbar: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		padding: theme.spacing(0, 1),
		...theme.mixins.toolbar,
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
		backgroundColor: theme.palette.background.default,
	},
}));

function NavWrapper(props) {
	const classes = useStyles();
	const [drawerOpen, setDrawerOpen] = useState(false);

	return (
		<div className={classes.root} data-test='root'>
			<Navigation
				useStyles={useStyles}
				drawerOpen={drawerOpen}
				setDrawerOpen={setDrawerOpen}
				data-test='rendered-navigation'
			/>
			<Box
				data-test='rendered-content'
				className={clsx(classes.content, {
					[classes.contentShift]: drawerOpen,
				})}
			>
				<div className={classes.toolbar} />
				{props.children}
			</Box>
		</div>
	);
}

export default NavWrapper;
