export const ACTION_TYPES = {
	SET_ALERT: '_SET_ALERT',
	RESET_ALERTS: '_RESET_ALERTS',
};

export const ALERT_TYPES = {
	SUCCESS: 'success',
	INFO: 'info',
	WARNING: 'warning',
	ERROR: 'error',
};

const initialState = {
	latest: { type: null, message: null },
	stack: [],
};

export default function alertsReducer(state = initialState, action) {
	switch (action.type) {
		case ACTION_TYPES.SET_ALERT:
			console.log(state.stack);
			const newStack = [...state.stack];
			newStack.push(action.payload);
			return {
				...state,
				stack: newStack,
				latest: action.payload,
			};
		case ACTION_TYPES.RESET_ALERTS:
			return {
				...initialState,
			};
		default:
			return state;
	}
}
