import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import JobHeader from '../../molecules/jobHeader';
import AnimatedFab from '../../atoms/animatedFab';
import FlightTakeoffIcon from '@material-ui/icons/FlightTakeoff';
import FlightTable from '../../organisms/flightTable';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';
import { updateDocumentsRead } from 'redux/actions/activityActions';
import { hasRole, ROLES } from 'utils/rolesConstants';

const useStyles = makeStyles((theme) => ({
	margin: {
		marginTop: theme.spacing(4),
		marginBottom: theme.spacing(4),
	},
}));

const JobPage = (props) => {
	const classes = useStyles();
	const { history, job, flights, uid, profile, updateJobsRead } = props;

	useEffect(() => {
		if (job && job.id) {
			updateJobsRead(job.id);
		}
	}, [updateJobsRead, job]);

	if (!job || !flights) return 'Loading...';

	return (
		<>
			<JobHeader
				data={job}
				startExpanded={false}
				canEdit={job.createdBy === uid || hasRole(profile.roles, ROLES.ADMIN)}
			/>
			<div className={classes.margin} />
			<FlightTable flightsObjArr={flights} jobObj={job} />
			<AnimatedFab
				icon={FlightTakeoffIcon}
				text='New Flight'
				action={() => history.push(`/jobs/${job.id}/new-flight`)}
			/>
		</>
	);
};

const mapStateToProps = (
	{ firestore: { data, ordered }, firebase },
	ownProps
) => ({
	job: data[`openJob[${ownProps.match.params.jobId}]`],
	flights: ordered[`openFlights[${ownProps.match.params.jobId}]`],
	uid: firebase.auth.uid,
	profile: firebase.profile,
});

const mapDispatchToProps = (dispatch) => ({
	updateJobsRead: (id) => dispatch(updateDocumentsRead('jobs', id)),
});

export default compose(
	firestoreConnect((ownProps) => [
		{
			collection: `jobs`,
			doc: `${ownProps.match.params.jobId}`,
			storeAs: `openJob[${ownProps.match.params.jobId}]`,
		},
		{
			collection: `jobs/${ownProps.match.params.jobId}/flights`,
			storeAs: `openFlights[${ownProps.match.params.jobId}]`,
		},
	]),
	connect(mapStateToProps, mapDispatchToProps)
)(JobPage);
