import React from 'react';
import { useHistory } from 'react-router-dom';
import AnimatedFab from '../../atoms/animatedFab';
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';
import { FLAT_ROUTES } from '../../../config/routes';
import { Grid, makeStyles, Typography, Button } from '@material-ui/core';
import AlgoliaSearchBar from 'components/molecules/AlgoliaSearchBar';

import JobFolder from '../../molecules/jobFolder';

import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import AlgoliaSearchResults from 'components/organisms/AlgoliaSearchResults';

const SECTION_LIMIT = 6;

const useStyles = makeStyles((theme) => ({
	bottomMargin: {
		marginBottom: '20px',
	},
	paper: {
		padding: '20px',
	},
	horizontalLine: {
		height: '1.5px',
		width: '100%',
		backgroundColor: theme.palette.divider,
		marginBottom: '20px',
	},
}));

function JobsPage(props) {
	const history = useHistory();
	const classes = useStyles();

	const uid = useSelector((state) => state.firebase.auth.uid);

	useFirestoreConnect(() => [
		{
			collection: 'jobs',
			storeAs: 'recentlyUpdatedJobs',
			limit: SECTION_LIMIT * 2,
			orderBy: ['lastUpdateAt', 'desc'],
		},
		{
			collection: `users/${uid}/activity`,
			doc: 'documentsRead',
			storeAs: 'documentsRead',
		},
	]);

	let recentlyUpdatedJobs = useSelector(
		(state) => state.firestore.ordered.recentlyUpdatedJobs
	);

	const documentsRead = useSelector(
		(state) => state.firestore.data.documentsRead
	);

	useFirestoreConnect(() => {
		if (!recentlyUpdatedJobs || !documentsRead || !documentsRead.jobs)
			return [];
		return [
			{
				collection: 'jobs',
				where: ['id', 'in', documentsRead.jobs.slice(0, SECTION_LIMIT)],
				storeAs: 'recentlyReadJobs',
				limit: SECTION_LIMIT,
			},
		];
	});

	let recentlyReadJobs = useSelector(
		(state) => state.firestore.ordered.recentlyReadJobs
	);

	if (!recentlyUpdatedJobs) {
		return <p> Loading... </p>;
	}

	try {
		if (
			Array.isArray(recentlyReadJobs) &&
			documentsRead &&
			documentsRead.jobs
		) {
			recentlyReadJobs = recentlyReadJobs.sort((a, b) => {
				return (
					documentsRead.jobs.indexOf(a.id) - documentsRead.jobs.indexOf(b.id)
				);
			});
			recentlyUpdatedJobs = recentlyUpdatedJobs
				.filter(
					({ id }) => !documentsRead.jobs.slice(0, SECTION_LIMIT).includes(id)
				)
				.slice(0, SECTION_LIMIT);
		}
	} catch (e) {
		console.log(e);
	}

	const scrollAndFocusSearch = () => {
		window.scrollTo(0, 0);
		document.getElementById('search-bar').focus();
	};

	return (
		<div data-test='flights-root'>
			<Grid container justify='left' className={classes.bottomMargin}>
				<Grid item xs={12}>
					<AlgoliaSearchBar index='JOBS' />
				</Grid>
			</Grid>
			<AlgoliaSearchResults index='JOBS' DisplayComponent={JobFolder} />
			{recentlyReadJobs && (
				<>
					<Typography className={classes.bottomMargin} variant='h5'>
						Recently Viewed
					</Typography>
					<div className={classes.horizontalLine} />
					<Grid container spacing={4} className={classes.bottomMargin}>
						{recentlyReadJobs.map((job, jobKey) => (
							<Grid item key={jobKey} xs={12} sm={6} md={4} lg={3} xl={2}>
								<JobFolder key={jobKey} data={job} />
							</Grid>
						))}
					</Grid>
				</>
			)}
			<Typography className={classes.bottomMargin} variant='h5'>
				Recently Updated
			</Typography>
			<div className={classes.horizontalLine} />
			<Grid container spacing={4} className={classes.bottomMargin}>
				{recentlyUpdatedJobs.map((job, jobKey) => (
					<Grid item key={jobKey} xs={12} sm={6} md={4} lg={3} xl={2}>
						<JobFolder key={jobKey} data={job} />
					</Grid>
				))}
			</Grid>
			<Typography variant='body1' align='center'>
				To find additional jobs, please{' '}
				<Button color='primary' onClick={scrollAndFocusSearch}>
					Search
				</Button>{' '}
				for them.
			</Typography>
			<AnimatedFab
				data-test='new-flight-button'
				icon={CreateNewFolderIcon}
				text='New Job'
				action={() => history.push(FLAT_ROUTES.NEW_JOB.path)}
			/>
		</div>
	);
}

export default JobsPage;
