import React from 'react';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';

/*var jsonData = {
	jobId: 'jobid123',
	accountName: 'accname',
	agencyMarkup: '35',
	businessUnit: 'abc',
	jobNumber: '12345',
	lastUpdateAt: Date.now(),
	lastUpdateBy: '67890',
	notes: 'some job notes',
	locked: true,
	flights: [
		{
			flightId: '1234',
			additionalInfo: 'adinfo',
			backgroundOpportunity: 'bgopp',
			budget: 'budg',
			channel: 'chann',
			competitiveConsiderations: 'compconsi',
			createdAt: Date.now(),
			createdBy: 'created-by',
			creativeUnit: 'unit',
			destinationUrl: 'dest-url',
			endDate: Date.now(),
			fileAttachmentsUrl: 'attachments',
			flightNotes: 'flight-notes',
			geoDetails: 'geo-detail',
			geoTargetingScope: 'geo-targeting',
			pastLearnings: 'past-learnings',
			measuredKPIs: 'kpis',
			platform: ['platform', 'platform2'],
			primaryKPI: 'primary-kpi',
			restriction: 'restrict',
			startDate: Date.now(),
			statusFlag: 'flag',
			targetAudience: 'target-aud',
			targetExclusions: 'target-exc',
		},
		{
			flightId: '4567',
			additionalInfo: 'adinfo',
			backgroundOpportunity: 'bgopp',
			budget: 'budg',
			channel: 'chann',
			competitiveConsiderations: 'compconsi',
			createdAt: Date.now(),
			createdBy: 'created-by',
			creativeUnit: 'unit',
			destinationUrl: 'dest-url',
			endDate: Date.now(),
			fileAttachmentsUrl: 'attachments',
			flightNotes: 'flight-notes',
			geoDetails: 'geo-detail',
			geoTargetingScope: 'geo-targeting',
			pastLearnings: 'past-learnings',
			measuredKPIs: 'kpis',
			platform: 'platform',
			primaryKPI: 'primary-kpi',
			restriction: 'restrict',
			startDate: Date.now(),
			statusFlag: 'flag',
			targetAudience: 'target-aud',
			targetExclusions: 'target-exc',
		},
	],
};*/

export function HomePage() {
	const history = useHistory();

	return (
		<div data-test='home-root'>
			<h2>Home</h2>
			<Button
				variant='contained'
				color='primary'
				onClick={() => history.push('/jobs')}
			>
				All Jobs
			</Button>
			<Button
				variant='contained'
				color='primary'
				onClick={() => history.push('/flights')}
				style={{ marginLeft: '10px' }}
			>
				All Flights
			</Button>
		</div>
	);
}

export default HomePage;
