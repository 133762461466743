import { ACTION_TYPES as ALERT_ACTIONS } from './alertsReducer';

export const ACTION_TYPES = {
	...ALERT_ACTIONS,
	NEW_FLIGHT_GROUP: 'MEDIA_BUY_NEW_FLIGHT_GROUP',
	UPDATE_FLIGHT_DRAFT: 'MEDIA_BUY_UPDATE_FLIGHT_DRAFT',
	SET_LOADING: 'MEDIA_BUY_SET_LOADING',
	RESET: 'MEDIA_BUY_RESET',
};

const initialState = {
	loading: false,
	newFlightGroupId: null,
	updatedFlightId: null,
};

export default function crowdReducer(state = initialState, action) {
	switch (action.type) {
		case ACTION_TYPES.NEW_FLIGHT_GROUP:
			return {
				...state,
				newFlightGroupId: action.payload,
			};
		case ACTION_TYPES.UPDATE_FLIGHT_DRAFT:
			return {
				...state,
				updatedFlightId: action.payload,
			};
		case ACTION_TYPES.SET_LOADING:
			return {
				...state,
				loading: action.payload,
			};
		case ACTION_TYPES.RESET:
			return initialState;
		default:
			return state;
	}
}
