import React from 'react';
import {
	TableRow,
	Grid,
	Tooltip,
	IconButton,
	Checkbox,
	TableCell,
	Typography,
} from '@material-ui/core';
import LinkIcon from '@material-ui/icons/Link';
import WarningIcon from '@material-ui/icons/Warning';
import FlightActions from '../flightActions';
import { formatForDisplay } from '../../../utils/fieldConverters';
import { Link } from 'react-router-dom';
import Skeleton from '@material-ui/lab/Skeleton';

const Cell = (props) => (
	<TableCell>
		<Typography {...props}>{props.value}</Typography>
	</TableCell>
);

const FlightTableRow = (props) => {
	const {
		isSelected,
		toggleSelect,
		flight,
		headers,
		isValid,
		validatedFlights,
	} = props;
	return (
		<TableRow
			hover
			role='checkbox'
			aria-checked={isSelected}
			selected={isSelected}
		>
			<TableCell padding='checkbox' onClick={(e) => e.stopPropagation()}>
				<Checkbox
					checked={isSelected}
					onClick={() => toggleSelect(flight)}
					disabled={!flight}
				/>
			</TableCell>
			<>
				{headers.map((field, idxTwo) => {
					return flight ? (
						<Cell value={formatForDisplay(field, flight[field])} key={idxTwo} />
					) : (
						<TableCell key={idxTwo}>
							<Skeleton height={50} animation='wave' />
						</TableCell>
					);
				})}
				{flight ? (
					<TableCell align='right' onClick={(e) => e.stopPropagation()}>
						<Grid container justify='flex-end'>
							{!isValid && (
								<Grid item>
									<Tooltip title={`Flight is missing required fields.`}>
										<IconButton>
											<WarningIcon />
										</IconButton>
									</Tooltip>
								</Grid>
							)}
							<Grid item>
								<Link to={`/jobs/${flight.parentJob}/flight/${flight.id}`}>
									<IconButton>
										<LinkIcon />
									</IconButton>
								</Link>
							</Grid>
							<Grid item>
								<FlightActions
									{...props}
									flightObjArr={[flight]}
									validatedIdArr={validatedFlights}
									variant='menu'
								/>
							</Grid>
						</Grid>
					</TableCell>
				) : (
					<TableCell align='right'>
						<Grid container justify='flex-end' spacing={2}>
							<Grid item>
								<Skeleton variant='circle' width={40} height={40} />
							</Grid>
							<Grid item>
								<Skeleton variant='circle' width={40} height={40} />
							</Grid>
							<Grid item>
								<Skeleton variant='circle' width={40} height={40} />
							</Grid>
						</Grid>
					</TableCell>
				)}
			</>
		</TableRow>
	);
};

export default FlightTableRow;
