import { getConfig, ENVIRONMENTS } from './environmentConfig';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';

// REACT_APP_ENVIRONMENT is set in package.json during the start/build commands
const CURRENT_REACT_ENV = window._env_.ENVIRONMENT;
// get the current configuration for the environment
const ENV_CONFIG = getConfig(CURRENT_REACT_ENV);
// initialize firebase app
firebase.initializeApp(ENV_CONFIG.firebase.config);
// if we're using the dev environment, we need to run the following to serve callable functions
if (ENV_CONFIG.name === ENVIRONMENTS.DEV) {
	firebase.firestore().settings({
		host: 'localhost:8080',
		ssl: false,
	});
	firebase.functions().useFunctionsEmulator(ENV_CONFIG.host);
}

firebase.firestore();
firebase.functions();
firebase.storage();

export default firebase;
