var cloneDeep = require('lodash.clonedeep');

export const GROUP_FIELDS = [
	{
		id: 'jobNumber',
		label: 'Job Number',
		required: true,
	},
	{
		id: 'accountName',
		label: 'Account Name',
		required: true,
	},
	{
		id: 'businessUnit',
		label: 'Business Unit',
		required: true,
	},
	{
		id: 'notes',
		label: 'Notes',
		multiline: true,
	},
	{
		id: 'campaignCode',
		label: 'Campaign Code',
		required: true,
	},
	{
		id: 'campaignName',
		label: 'Campaign Name',
		multiline: true,
	},
	{
		map: true,
		id: 'analytics',
		label: 'Analytics Fields',
		fields: [
			{
				id: 'reportingSupportCoveredByCommissionOrHours',
				label: 'Is reporting support covered by commission or hours?',
				options: [{ value: 'Commission' }, { value: 'Hours Billed' }],
			},
			{
				id: 'analyticsJobNumber',
				label: 'Analytics Job Number',
				dependencies: [
					{
						dependentOn: 'reportingSupportCoveredByCommissionOrHours',
						values: ['Hours Billed'],
					},
				],
			},
			{
				id: 'dashboardLoginNeeded',
				label: 'External Dashboard Needed?',
				options: [{ value: 'Yes' }, { value: 'No' }],
			},
			{
				id: 'userLogins',
				label: 'User Logins (Email Addresses)',
				multiline: true,
			},
			{
				id: 'presentationNeeded',
				label: 'Is a Presentation Needed?',
				options: [{ value: 'Yes' }, { value: 'No' }],
			},
			{
				id: 'analyticsResourceNeededForPresentation',
				label: 'Is an Analytics Resource Needed for Presentation?',
				options: [{ value: 'Yes' }, { value: 'No' }],
				dependencies: [{ dependentOn: 'presentationNeeded', values: ['Yes'] }],
			},
			{
				id: 'reportOutputNeeded',
				label: 'Report Output Needed',
				options: [{ value: 'PDF' }, { value: 'PPT' }, { value: 'Word Doc' }],
			},
			{
				id: 'benchmarksNeededForReporting',
				label: 'Are Benchmarks Needed for Reporting?',
				options: [{ value: 'Yes' }, { value: 'No' }],
			},
			{
				id: 'sourceOfBenchmarks',
				label: 'Source of Benchmarks',
				options: [
					{ value: 'Analytics Team Recommendation' },
					{ value: 'Other' },
				],
				dependencies: [
					{ dependentOn: 'benchmarksNeededForReporting', values: ['Yes'] },
				],
			},
			{
				id: 'otherBenchmarkSource',
				label: 'Benchmark Source',
				multiline: true,
				dependencies: [
					{ dependentOn: 'sourceOfBenchmarks', values: ['Other'] },
				],
			},
			{
				id: 'reportingCadence',
				label: 'Reporting Cadence',
				options: [
					{ value: 'Weekly' },
					{ value: 'Monthly' },
					{ value: 'Quarterly' },
					{ value: 'Other' },
				],
			},
			{
				id: 'campaignRecapReportNeeded',
				label: 'Is a Campaign Recap (Entire Timeframe) Report Needed?',
				options: [{ value: 'Yes' }, { value: 'No' }],
			},
			{
				id: 'campaignRecapReportReplacesLastReport',
				label: 'Does Campaign Recap Report Replace Last Scheduled Report?',
				options: [{ value: 'Yes' }, { value: 'No' }],
				dependencies: [
					{ dependentOn: 'campaignRecapReportNeeded', values: ['Yes'] },
				],
			},
			{
				id: 'reportRecipients',
				label: 'Report Recipients (Email Addresses)',
				multiline: true,
			},
			{
				id: 'analyticsNotes',
				label: 'Analytics Notes',
				multiline: true,
			},
			{
				id: 'costMarkup',
				label: 'Cost Markup',
				options: [{ value: 'Client Gross' }, { value: 'MRX Gross' }],
			},
			{
				id: 'googleAnalyticsManagedOrAcessGranted',
				label: 'Is Google Analytics measurement required?',
				options: [{ value: 'Yes' }, { value: 'No' }],
			},
		],
	},
];

const CHANNEL_TYPES = {
	DISPLAY: 'Display',
	OTT: 'OTT',
	DIGITAL_AUDIO: 'Digital Audio',
	SOCIAL: 'Social',
	VIDEO: 'Video',
	SEM: 'SEM',
	APP_STORE: 'App Store',
	NATIVE: 'Native',
};
const C = CHANNEL_TYPES;

const PLATFORM_TYPES = {
	ADOBE: 'Adobe',
	GDN: 'GDN',
	OTHER: 'Other',
	MRX_RECO: 'MRX Recommendation',
	SPOTIFY: 'Spotify',
	PANDORA: 'Pandora',
	TUNE_IN: 'Tune-In',
	SOUNDCLOUD: 'SoundCloud',
	I_HEART: 'iHeart',
	OPEN_EXCHANGE: 'Open Exchange',
	TARGET_SPOT: 'Target Spot',
	FACEBOOK: 'Facebook',
	INSTAGRAM: 'Instagram',
	APPLE: 'Apple',
	GOOGLE: 'Google',
	LINKED_IN: 'LinkedIn',
};
const P = PLATFORM_TYPES;

export const FLIGHT_FIELDS = [
	[
		{
			id: 'channel',
			label: 'Channel',
			required: true,
			options: Object.values(C).map((channel) => ({ value: channel })),
		},
		{
			id: 'platform',
			label: 'Platform',
			isDynamic: true,
			required: true,
			multiple: { whenNotChannel: [C.DISPLAY, C.SEM] },
			options: {
				[C.DISPLAY]: [P.ADOBE, P.GDN, P.OTHER, P.MRX_RECO].map((platform) => ({
					value: platform,
				})),
				[C.OTT]: [P.ADOBE, P.OTHER, P.MRX_RECO].map((platform) => ({
					value: platform,
				})),
				[C.DIGITAL_AUDIO]: [
					P.SPOTIFY,
					P.PANDORA,
					P.TUNE_IN,
					P.SOUNDCLOUD,
					P.I_HEART,
					P.OPEN_EXCHANGE,
					P.TARGET_SPOT,
					P.OTHER,
					P.MRX_RECO,
				].map((platform) => ({ value: platform })),
				[C.SOCIAL]: [
					P.FACEBOOK,
					P.INSTAGRAM,
					P.OTHER,
					P.MRX_RECO,
					P.LINKED_IN,
				].map((platform) => ({ value: platform })),
				[C.VIDEO]: [P.ADOBE, P.OTHER, P.MRX_RECO].map((platform) => ({
					value: platform,
				})),
				[C.SEM]: [P.MRX_RECO].map((platform) => ({ value: platform })),
				[C.APP_STORE]: [P.APPLE, P.GOOGLE, P.OTHER, P.MRX_RECO].map(
					(platform) => ({
						value: platform,
					})
				),
				[C.NATIVE]: [P.ADOBE, P.OTHER, P.MRX_RECO].map((platform) => ({
					value: platform,
				})),
			},
			dependencies: [{ dependentOn: 'channel', values: Object.values(C) }],
		},
		{
			id: 'otherPlatformDescription',
			label: 'Platform Details',
			multiline: true,
			dependencies: [{ dependentOn: 'platform', values: [P.OTHER] }],
		},
		{
			id: 'creativeUnit',
			label: 'Creative Unit',
			isDynamic: true,
			dependencies: [{ dependentOn: 'channel', values: [C.SOCIAL] }],
			multiple: { whenNotPlatform: [P.LINKED_IN] },
			required: true,
			options: [
				'Static',
				'Carousel',
				'Video',
				'Cinemograph',
				'Collection',
			].map((unit) => ({ value: unit })),
		},
	],
	[
		{
			id: 'destinationUrl',
			label: 'Destination URL',
			type: 'url',
			required: true,
			adornments: { start: 'https://' },
		},
		{
			id: 'flightNotes',
			label: 'Flight Notes',
			multiline: true,
		},
		{
			id: 'startDate',
			label: 'Flight Start Date',
			required: true,
			type: 'date',
			value: Date.now(),
			helperText:
				"If less than 14 days of today's date, prior sign off is required.",
		},
		{
			id: 'endDate',
			label: 'Flight End Date',
			required: true,
			type: 'date',
			value: Date.now(),
		},
		{
			id: 'budget',
			label: 'Budget',
			helperText: 'Listed in MRX Gross.',
			required: true,
			type: 'number',
			step: 0.01,
			adornments: { start: '$' },
		},
		{
			id: 'targetAudience',
			label: 'Target Audience',
			required: true,
			multiline: true,
			helperText:
				'Define the Target Audience including any details on age, gender, behavior, or access to first party data etc. NOTE: smaller media budgets or limited geo scope should avoid granular targeting requests.',
		},
		{
			id: 'geoTargetingScope',
			label: 'Geo Targeting Scope',
			helperText:
				'Select the geo scope that we will focus on for targeting. NOTE: for Zip Exclusion select DMA as Targeting Scope.',
			required: true,
			options: [
				{ value: 'DMA' },
				{ value: 'County' },
				{ value: 'City' },
				{ value: 'State' },
				{ value: 'Country' },
				{ value: 'Zip List' },
				{ value: 'Other' },
			],
		},
		{
			id: 'geoDetails',
			label: 'Geo Details',
			multiline: true,
			helperText:
				'Include details based on your selected scope. Zip code targeting is achieved by using ZIP EXCLUSION approach.',
		},
		{
			id: 'targetingExclusions',
			multiline: true,
			label: 'Targeting Exclusions',
			helperText:
				'Ex. Exclude Michigan and Ohio from National Targeting Ex. Exclude the attached list of zips from DMA.',
		},
		{
			id: 'fileAttachmentsUrl',
			label: 'File Attachments',
			type: 'url',
			helperText:
				'Provide link or the File Location on Server to a list for inclusion or exclusion targeting (if applicable) For zip code targeting - only provide list of excluded zip codes to be removed from DMA.',
		},
		{
			id: 'objective',
			label: 'Objective',
			helperText:
				'Select the objective that your client is focused on. Will influence optimization goals, tactics, estimates.',
			required: true,
			options: [
				{ value: 'Conversion' },
				{ value: 'Drive Awareness' },
				{ value: 'Lead Generation' },
				{ value: 'Site Traffic' },
			],
		},
		{
			id: 'objectiveDescription',
			label: 'Objective Description',
			multiline: true,
		},
		{
			id: 'primaryKPI',
			required: true,
			label: 'Primary KPI',
		},
		{
			id: 'measuredKPIs',
			label: 'Measured KPIs',
			multiline: true,
		},
		{
			id: 'backgroundOpportunity',
			label: 'Background / Opportunity',
			multiline: true,
			helperText:
				'Provide background information if applicable or necessary for media launch.',
		},
		{
			id: 'competitiveConsiderations',
			label: 'Competitive Considerations',
			multiline: true,
			helperText:
				'Are there any competitor considerations we should be mindful of?',
		},
		{
			id: 'restrictions',
			label: 'Restrictions',
			multiline: true,
		},
		{
			id: 'pastLearnings',
			label: 'Past Learnings',
			multiline: true,
		},
		{
			id: 'additionalInfo',
			label: 'Additional Info',
			multiline: true,
		},
	],
];

const _buildDefaultErrorObj = (obj, val = {}) => {
	Object.keys(obj).forEach((key) => {
		if (obj[key] !== null && typeof obj[key] === 'object') {
			obj[key] = _buildDefaultErrorObj(obj[key]);
		} else {
			obj[key] = val;
		}
	});
	return obj;
};

const getDefaults = (fields) => {
	let defaults = {};
	fields.forEach((field) => {
		if (field.map) {
			defaults[field.id] = getDefaults(field.fields);
		} else {
			defaults[field.id] = field.value ? field.value : '';
		}
	});
	return defaults;
};

export const GROUP_DEFAULTS = getDefaults(GROUP_FIELDS);
export const GROUP_ANALYTICS_LABELS = GROUP_FIELDS.find(
	({ id }) => id === 'analytics'
).fields.map(({ id, label }) => {
	return { id, label };
});

// flatten flight field pages to single array of fields
export const FLIGHT_FIELDS_FLAT = [].concat.apply([], FLIGHT_FIELDS);

export const FLIGHT_DEFAULTS = getDefaults(FLIGHT_FIELDS_FLAT);

export const GROUP_ERROR_DEFAULTS = _buildDefaultErrorObj(
	cloneDeep(GROUP_DEFAULTS)
);
export const FLIGHT_ERROR_DEFAULTS = _buildDefaultErrorObj(
	cloneDeep(FLIGHT_DEFAULTS)
);
