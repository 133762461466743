/* eslint-disable no-case-declarations */
import React, { useState, useEffect, useCallback } from 'react';
import {
	TextField,
	InputAdornment,
	MenuItem,
	Checkbox,
	makeStyles,
	Typography,
	Grid,
} from '@material-ui/core';
import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { getUnixTime } from 'date-fns';
import { formatForDisplay } from '../../../utils/fieldConverters';
import clsx from 'clsx';

var pick = require('lodash.pick');

const VARIANTS = {
	SINGLE_SELECT: '_SINGLE_SELECT',
	MULTI_SELECT: '_MULTI_SELECT',
	TEXT: '_TEXT',
	DATE: '_DATE',
	READ_ONLY: '_READ_ONLY',
};

const allFieldDefaults = {
	'data-test': 'root',
	fullWidth: true,
	margin: 'normal',
	variant: 'filled',
};

const useStyles = makeStyles((theme) => ({
	[VARIANTS.SINGLE_SELECT]: {
		minWidth: 300,
	},
	[VARIANTS.MULTI_SELECT]: {
		minWidth: 300,
	},
	[VARIANTS.TEXT]: {
		minWidth: 300,
	},
	[VARIANTS.DATE]: {
		minWidth: 300,
	},
	rejectedColor: {
		color: theme.palette.error.main,
		outlineColor: theme.palette.error.main,
	},
}));

const _guardForArray = (value) => {
	if (Array.isArray(value)) return value;
	else return [];
};

const ReadOnlyVariant = (props) => {
	const classes = useStyles();
	const { rejected } = props;
	const MainContent = (
		<div className={clsx(rejected && classes.rejectedColor)}>
			<Typography>{props.label}</Typography>
			<Typography>
				<b>{formatForDisplay(props.label, props.value)}</b>
			</Typography>
		</div>
	);

	if (props.showCheckBox) {
		return (
			<Grid container>
				<Grid item>
					<Checkbox
						checked={props.rejected}
						onChange={() => props.toggleFieldSelected(props.id)}
					/>
				</Grid>
				<Grid item>{MainContent}</Grid>
			</Grid>
		);
	}

	return MainContent;
};

const TextFieldVariant = (props) => {
	const classes = useStyles();
	return (
		<TextField
			className={classes[props.fieldVariant]}
			{...props}
			InputProps={{
				startAdornment: props.adornments && (
					<InputAdornment position='start'>
						{props.adornments.start ? props.adornments.start : ''}
					</InputAdornment>
				),
				endAdornment: props.adornments && (
					<InputAdornment position='end'>
						{props.adornments.end ? props.adornments.end : ''}
					</InputAdornment>
				),
				className: clsx(
					props.rejected && !props.changedFromRemote && classes.rejectedColor
				),
			}}
			error={props.error || props.rejected}
		/>
	);
};

const SingleSelectVariant = (props) => {
	const classes = useStyles();
	return (
		<TextFieldVariant className={classes[props.fieldVariant]} {...props} select>
			{props.options.map((option, idx) => (
				<MenuItem idx={idx} key={idx} value={option.value}>
					{option.label || option.value}
				</MenuItem>
			))}
		</TextFieldVariant>
	);
};

const MultiSelectVariant = (props) => {
	const classes = useStyles();
	return (
		<>
			<TextFieldVariant
				className={classes[props.fieldVariant]}
				{...props}
				select
				SelectProps={{
					multiple: true,
					renderValue: (value) =>
						typeof value === 'object' ? value.join(', ') : value,
				}}
			>
				{props.options.map((option, idx) => (
					<MenuItem idx={idx} key={idx} value={option.value}>
						<Checkbox checked={props.value.indexOf(option.value) > -1} />
						{option.label || option.value}
					</MenuItem>
				))}
			</TextFieldVariant>
		</>
	);
};

const DateVariant = (props) => {
	const classes = useStyles();
	// eslint-disable-next-line no-unused-vars
	const { variant, type, handleDateChange, value, ...restProps } = props;

	return (
		<MuiPickersUtilsProvider utils={DateFnsUtils}>
			<KeyboardDatePicker
				disableToolbar
				inputVariant='filled'
				KeyboardButtonProps={{
					'aria-label': 'change date',
				}}
				format='MM/dd/yyyy'
				variant='inline'
				onChange={(date) => {
					const converted = getUnixTime(date) * 1000;
					handleDateChange(props.id, converted);
				}}
				className={classes[props.fieldVariant]}
				initialFocusedDate={Date.now()}
				inputProps={{
					className: clsx(
						props.rejected && !props.changedFromRemote && classes.rejectedColor
					),
				}}
				autoOk
				{...restProps}
				value={value || null}
				error={props.error || props.rejected || isNaN(value)}
			/>
		</MuiPickersUtilsProvider>
	);
};

const MediaBuyFormField = (props) => {
	const {
		field,
		parentField,
		fieldValue,
		handleChange,
		handleDateChange,
		fieldError,
		formValues,
		readOnly,
		rejected,
		changedFromRemote,
	} = props;

	const _getDependencies = () => {
		if (!field.dependencies) return null;
		const dependencyArray = field.dependencies.map((d) => d.dependentOn);
		return pick(formValues, dependencyArray);
	};
	const dependencyValues = _getDependencies();
	const [localValue, setLocalValue] = useState(fieldValue);
	const [knownDependencies, setKnownDependencies] = useState(dependencyValues);

	const storeFieldValue = (e) => {
		setLocalValue(e.target.value);
		// for large groups of fields we only push standard textfield changes on blur, but this prop overrides this behavior
		if (props.realTimeTextPush) handleChange(e, parentField.id);
	};

	const _simulateFieldChange = useCallback(
		(newLocalValue) => {
			handleChange(
				{ target: { id: field.id, value: newLocalValue } },
				parentField.id
			);
		},
		[handleChange, field.id, parentField.id]
	);

	const _resetFieldToDefault = useCallback(() => {
		setKnownDependencies(dependencyValues);
		const newLocalValue = field.value ? field.value : field.multiple ? [] : '';
		_simulateFieldChange(newLocalValue);
	}, [dependencyValues, field.value, field.multiple, _simulateFieldChange]);

	// clear the value of the field if a dependency of the field changes
	useEffect(() => {
		for (const fieldId in dependencyValues) {
			if (dependencyValues[fieldId] !== knownDependencies[fieldId]) {
				_resetFieldToDefault();
				return;
			}
		}
	}, [dependencyValues, _resetFieldToDefault, knownDependencies]);

	let variantName;
	if (readOnly) variantName = VARIANTS.READ_ONLY;
	else if (field.type === 'date') variantName = VARIANTS.DATE;
	else if (field.options && field.multiple) variantName = VARIANTS.MULTI_SELECT;
	else if (field.options && !field.multiple)
		variantName = VARIANTS.SINGLE_SELECT;
	else variantName = variantName = VARIANTS.TEXT;

	switch (variantName) {
		case VARIANTS.READ_ONLY:
			return (
				<ReadOnlyVariant
					{...field}
					value={fieldValue}
					showCheckBox={props.showCheckBox}
					toggleFieldSelected={props.toggleFieldSelected}
					rejected={rejected}
					changedFromRemote={changedFromRemote}
					name={field.id}
				/>
			);
		case VARIANTS.DATE:
			return (
				<DateVariant
					{...allFieldDefaults}
					error={!!fieldError}
					{...field}
					value={fieldValue}
					handleDateChange={handleDateChange}
					rejected={rejected}
					changedFromRemote={changedFromRemote}
					name={field.id}
				/>
			);
		case VARIANTS.MULTI_SELECT:
			let guardedValue = _guardForArray(fieldValue);
			return (
				<MultiSelectVariant
					{...allFieldDefaults}
					error={!!fieldError}
					{...field}
					value={guardedValue}
					onChange={(e) => handleChange(e, parentField.id)}
					rejected={rejected}
					changedFromRemote={changedFromRemote}
					name={field.id}
				/>
			);
		case VARIANTS.SINGLE_SELECT:
			return (
				<SingleSelectVariant
					{...allFieldDefaults}
					error={!!fieldError}
					{...field}
					value={fieldValue}
					onChange={(e) => handleChange(e, parentField.id)}
					rejected={rejected}
					changedFromRemote={changedFromRemote}
					name={field.id}
				/>
			);
		case VARIANTS.TEXT:
			// text field uses and updates against a local value and only pushes up changes on blur for performance reasons
			return (
				<TextFieldVariant
					{...allFieldDefaults}
					error={!!fieldError}
					{...field}
					value={localValue}
					onChange={storeFieldValue}
					onBlur={(e) => handleChange(e, parentField.id)}
					rejected={rejected}
					changedFromRemote={changedFromRemote}
					name={field.id}
				/>
			);
		default:
			return null;
	}
};

export default MediaBuyFormField;
