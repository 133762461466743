import React, { useState } from 'react';
import { GROUP_FIELDS, GROUP_DEFAULTS } from '../../../config/flightDataModel';
import MediaBuyFormField from '../../atoms/mediaBuyFormField';
import {
	Button,
	Grid,
	Typography,
	CircularProgress,
	Paper,
	makeStyles,
	Divider,
} from '@material-ui/core';
import cloneDeep from 'lodash.clonedeep';
import { useHistory } from 'react-router-dom';
import { FLAT_ROUTES } from '../../../config/routes';
import { connect } from 'react-redux';
import { updateJob } from '../../../redux/actions/mediaBuyActions.ts';
import validateForm from '../../../utils/validateForm';
import _passesDependencies from '../../../utils/passesDependencies';

const useStyles = makeStyles((theme) => ({
	paper: {
		padding: theme.spacing(2),
		maxWidth: '1280px',
	},
	button: {
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(2),
		marginLeft: theme.spacing(2),
	},
	header: {
		marginBottom: theme.spacing(2),
	},
}));

const fillOutRemote = (remote) => {
	const defaults = cloneDeep(GROUP_DEFAULTS);
	Object.keys(defaults).forEach((key) => {
		if (!remote[key]) remote[key] = cloneDeep(defaults[key]);
	});
	return remote;
};

const JobForm = (props) => {
	const classes = useStyles();
	let { remoteData } = cloneDeep(props);
	// if remoteData is passed to the form, use it. Otherwise, use the defaults
	const [formValues, setFormValues] = useState(
		remoteData ? fillOutRemote(remoteData) : cloneDeep(GROUP_DEFAULTS)
	);
	const [formErrors, setFormErrors] = useState({});

	const History = useHistory();

	const handleChange = (e, parentId = null) => {
		const { target } = e;
		let { id, value, name } = target;
		if (!id && !!name) id = name;
		setFormValues((prev) => {
			let newState = Object.assign({}, prev);
			if (parentId) {
				newState[parentId][id] = value;
			} else newState[id] = value;
			return newState;
		});
	};

	const handleSubmit = () => {
		const errors = validateForm(formValues, GROUP_FIELDS);
		setFormErrors(errors);
		if (!Object.keys(errors).length > 0) {
			props.updateJob(formValues).then((jobObj) => {
				if (!jobObj.id) return;
				History.push(`/jobs/${jobObj.id}`);
			});
		}
	};

	const handleCancel = () => {
		if (!remoteData) History.push(FLAT_ROUTES.JOBS.path);
		else History.push(`/jobs/${remoteData.id}`);
	};

	return (
		<Paper className={classes.paper} elevation={4}>
			<Typography variant='h6' className={classes.header}>
				<b>{remoteData ? 'Edit' : 'Add'} job information</b>
			</Typography>
			<Grid container spacing={3}>
				{GROUP_FIELDS.map((field, idx) => {
					if (!field.map) {
						return (
							<Grid key={idx} item xs={12} md={4}>
								<MediaBuyFormField
									field={field}
									parentField={{ id: null }}
									fieldValue={formValues[field.id]}
									fieldError={formErrors[field.id]}
									formValues={formValues}
									handleChange={handleChange}
								/>
							</Grid>
						);
					} else {
						return (
							<Grid item container xs={12} spacing={3}>
								<Grid item xs={12}>
									<Typography variant='h6'>{field.label}</Typography>
									<Divider />
								</Grid>
								{field.fields.map((subField, idx) => {
									if (
										subField.dependencies &&
										!_passesDependencies(subField, formValues[field.id])
									) {
										// clear the value of the field on the form if necassary because it's not available in the form
										if (formValues[field.id][subField.id])
											handleChange(
												{ target: { id: subField.id, value: '' } },
												field.id
											);
										return null;
									}
									return (
										<Grid key={idx} item xs={12} md={6}>
											<MediaBuyFormField
												field={subField}
												parentField={field}
												fieldValue={formValues[field.id][subField.id]}
												fieldError={
													formErrors[field.id] &&
													formErrors[field.id][subField.id]
												}
												formValues={formValues}
												handleChange={handleChange}
											/>
										</Grid>
									);
								})}
							</Grid>
						);
					}
				})}
			</Grid>
			{props.loading ? (
				<CircularProgress />
			) : (
				<div className={classes.buttonContainer}>
					<Button
						className={classes.button}
						children={'Cancel'}
						onClick={handleCancel}
					/>
					<Button
						className={classes.button}
						color='primary'
						variant='contained'
						children={remoteData ? 'Save' : 'Create'}
						onClick={() => handleSubmit()}
					/>
				</div>
			)}
		</Paper>
	);
};

const mapStateToProps = (state) => ({
	loading: state.mediaBuy.loading,
});

const mapDispatchToProps = (dispatch) => ({
	updateJob: (jobObj) => dispatch(updateJob(jobObj)),
});

export default connect(mapStateToProps, mapDispatchToProps)(JobForm);
