import React from 'react';
import { Snackbar, makeStyles, Fade } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		'& > * + *': {
			marginTop: theme.spacing(2),
		},
	},
}));

const Mui = (props) => {
	return <MuiAlert elevation={5} variant='filled' {...props} />;
};

/**
 * Floating snackbar-style alert
 */
const Alert = (props) => {
	const classes = useStyles();
	const {
		closeCallback,
		severity,
		message,
		open,
		stackSize,
		duration,
		noAutoHide,
	} = props;

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') return;
		closeCallback();
	};

	return (
		<div className={classes.root}>
			<Snackbar
				open={open}
				onClose={handleClose}
				key={stackSize}
				autoHideDuration={noAutoHide ? null : duration || 5000}
				TransitionComponent={Fade}
			>
				<Mui severity={severity} onClose={handleClose}>
					{message}
				</Mui>
			</Snackbar>
		</div>
	);
};

Alert.propTypes = {
	/**
	 * The callback function called when the alert closes
	 */
	closeCallback: PropTypes.func,
	/**
	 * The type of alert that informs the visuals of the alert
	 */
	severity: PropTypes.oneOf(['success', 'info', 'warning', 'error']).isRequired,
	/**
	 * The text to display on the alert
	 */
	message: PropTypes.string.isRequired,
	/**
	 * A prop that, when different than previous, will force the alert wrapper to re-render. Useful for queing up additional alerts and animating them in and out accordingly.
	 */
	stackSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
		.isRequired,
	/**
	 * Whether or not the alert should be visible
	 */
	open: PropTypes.bool.isRequired,
};

export default Alert;
