const _passesDependencies = (field, formValues) => {
	if (!field.dependencies) return true;
	for (const dependency of field.dependencies) {
		const currentValue = formValues[dependency.dependentOn];
		if (typeof currentValue === 'string') {
			if (!dependency.values.includes(formValues[dependency.dependentOn]))
				return false;
		} else if (Array.isArray(currentValue)) {
			const matches = dependency.values.filter((element) =>
				currentValue.includes(element)
			);
			if (!matches || matches.length < 1) return false;
		}
	}
	return true;
};

export default _passesDependencies;
