export const ACTION_TYPES = {
	SET_LAST_DOC_READ: '_ACTIVITY_SET_LAST_DOC_READ',
	SET_LOADING: '_ACTIVITY_SET_LOADING',
};

const initialState = {
	lastDocRead: {},
	loading: false,
};

export default function alertsReducer(state = initialState, action) {
	switch (action.type) {
		case ACTION_TYPES.SET_LAST_DOC_READ:
			return {
				...state,
				lastDocRead: {
					...state.lastDocRead,
					[action.payload.docType]: action.payload.id,
				},
			};
		case ACTION_TYPES.SET_LOADING:
			return {
				...state,
				loading: action.payload,
			};
		default:
			return state;
	}
}
