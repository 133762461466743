import React, { useState } from 'react';
import { Grid, TextField, Button, CircularProgress } from '@material-ui/core';
import { connect } from 'react-redux';
import { testerEmailPasswordLogin } from 'redux/actions/authActions';

const defaultFormValues = {
	email: '',
	password: '',
};

export function TestLoginForm(props) {
	const [formValues, setFormValues] = useState(defaultFormValues);
	const { loading } = props;

	const handleChange = (event) => {
		const newFormValues = { ...formValues };
		newFormValues[event.target.name] = event.target.value;
		setFormValues(newFormValues);
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		props.doLogin(formValues);
		setFormValues(defaultFormValues);
	};

	return (
		<>
			<form>
				<Grid
					data-test='root'
					container
					item
					direction='column'
					spacing={2}
					alignItems='center'
				>
					<Grid item>
						<TextField
							variant='outlined'
							label='Test Account Email'
							name='email'
							value={formValues['email']}
							onChange={handleChange}
							fullWidth={true}
							type='email'
						/>
					</Grid>
					<Grid item>
						<TextField
							variant='outlined'
							label='Test Account Password'
							name='password'
							value={formValues['password']}
							type='password'
							autoComplete='current-password'
							onChange={handleChange}
							fullWidth={true}
						/>
					</Grid>
					<Grid item>
						{!loading ? (
							<Button
								variant='contained'
								color='secondary'
								children='Use Test Account'
								fullWidth={true}
								type='submit'
								onClick={handleSubmit}
							/>
						) : (
							<CircularProgress size={24} />
						)}
					</Grid>
				</Grid>
			</form>
		</>
	);
}

const mapDispatchToProps = (dispatch) => ({
	doLogin: (creds) => dispatch(testerEmailPasswordLogin(creds)),
});

const mapStateToProps = (state) => ({
	alert: state.auth.alert,
	loading: state.auth.loading,
});

export default connect(mapStateToProps, mapDispatchToProps)(TestLoginForm);
