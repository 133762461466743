import { combineReducers } from 'redux';
import { firebaseReducer } from 'react-redux-firebase';
import { firestoreReducer } from 'redux-firestore';

import routesReducer from './routesReducer';
import authReducer from './authReducer';
import mediaBuyReducer from './mediaBuyReducer';
import alertsReducer from './alertsReducer';
import algoliaReducer from './algoliaReducer';
import activityReducer from './activityReducer';
import versionSyncReducer from './versionSyncReducer';
import notificationReducer from './notificationReducer';
import flightTableReducer from './flightTableReducer';

export default combineReducers({
	firebase: firebaseReducer,
	firestore: firestoreReducer,
	routes: routesReducer,
	auth: authReducer,
	mediaBuy: mediaBuyReducer,
	alerts: alertsReducer,
	algolia: algoliaReducer,
	activity: activityReducer,
	versionSync: versionSyncReducer,
	notificationFeed: notificationReducer,
	flightTables: flightTableReducer,
});
