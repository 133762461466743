import React from 'react';
import { connect } from 'react-redux';
import { Button } from '@material-ui/core';
import { doLogout } from '../../../redux/actions/authActions';

function PendingUser(props) {
	const { profile, doLogout } = props;
	return (
		<div data-test='404-root'>
			<h1>Permissions Required.</h1>
			<p>Please contact a system administrator to let you in.</p>
			<br />
			<p>Thanks for flying with us.</p>
			<br />
			<p>Logged in as: {profile.displayName || profile.email}</p>
			<Button
				data-test='logout-button'
				color='primary'
				children='Log Out'
				variant='contained'
				onClick={doLogout}
			/>
		</div>
	);
}

const mapStatetoProps = (state) => ({
	profile: state.firebase.profile,
});

const mapDispatchToProps = (dispatch) => ({
	doLogout: () => dispatch(doLogout()),
});

export default connect(mapStatetoProps, mapDispatchToProps)(PendingUser);
