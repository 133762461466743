import React, { useState } from 'react';
import { Container, Grid } from '@material-ui/core';
import SignInWithMicrosoft from 'components/atoms/signInWithMicrosoft';
import LockIcon from '@material-ui/icons/Lock';
import { ENVIRONMENTS } from 'config/environmentConfig';
import queryString from 'query-string';
import TestLoginForm from 'components/organisms/testLoginForm';

const getVersionString = () => {
	let { BUILD, SEMVER, ENVIRONMENT } = window._env_;
	// eslint-disable-next-line
	if (BUILD === '${build_number}') BUILD = 'no-build';
	// eslint-disable-next-line
	if (ENVIRONMENT === '${environment}') ENVIRONMENT = 'Development';
	else ENVIRONMENT = ENVIRONMENT.charAt(0).toUpperCase() + ENVIRONMENT.slice(1);
	return `${SEMVER} (${BUILD}) ${ENVIRONMENT}`;
};

const notProduction = () => {
	return window._env_.ENVIRONMENT !== ENVIRONMENTS.PRODUCTION;
};

function LoginPage(props) {
	const { location } = props;
	const parsed = queryString.parse(location.search);
	const [showTestLogin] = useState(
		parsed.show === 'tester-login' && notProduction()
	);

	return (
		<>
			<Container data-test='login-root'>
				<Grid
					container
					direction='column'
					alignItems='center'
					justify='center'
					style={{ paddingTop: '100px' }}
					spacing={4}
				>
					<Grid item>
						<LockIcon />
					</Grid>
					{!showTestLogin ? (
						<Grid item>
							<SignInWithMicrosoft />
						</Grid>
					) : (
						<Grid item>
							<TestLoginForm />
						</Grid>
					)}
					<Grid item>v{getVersionString()}</Grid>
				</Grid>
			</Container>
		</>
	);
}

export default LoginPage;
