import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { initSearchClient, searchIndex } from 'redux/actions/algoliaActions';
import {
	TextField,
	IconButton,
	makeStyles,
	InputAdornment,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles({
	root: {},
});

const capitalize = (word) => {
	const firstLetter = word.charAt(0);
	const remainingLetters = word.slice(1);
	return firstLetter.toUpperCase() + remainingLetters.toLowerCase();
};

const AlgoliaSearchBar = (props) => {
	const { startSearchClient, index, searchClient, search, loading } = props;
	const [searchFieldText, setSearchFieldText] = useState('');
	const classes = useStyles();

	useEffect(() => {
		startSearchClient();
	}, [startSearchClient]);

	const handleChange = (e) => {
		setSearchFieldText(e.target.value);
	};

	const handleSearch = () => {
		if (searchFieldText.length < 2 || loading) return;
		search(index, searchFieldText);
	};

	const handleKeyPress = (e) => {
		if (e.key === 'Enter') handleSearch();
	};

	return (
		<div className={classes.root}>
			<TextField
				id='search-bar'
				value={searchFieldText}
				onChange={handleChange}
				disabled={!searchClient || loading}
				variant='outlined'
				placeholder={`Search ${capitalize(index)}`}
				fullWidth
				onKeyDown={handleKeyPress}
				InputProps={{
					startAdornment: (
						<InputAdornment position='start'>
							<IconButton onClick={handleSearch}>
								<SearchIcon />
							</IconButton>
						</InputAdornment>
					),
				}}
			/>
		</div>
	);
};

const mapStateToProps = (state) => ({
	searchClient: state.algolia.searchClient,
	loading: state.algolia.loading,
});

const mapDispatchToProps = (dispatch) => ({
	startSearchClient: () => dispatch(initSearchClient()),
	search: (index, query) => dispatch(searchIndex(index, query)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AlgoliaSearchBar);
