import React, { useState, useMemo } from 'react';
import {
	Card,
	CardHeader,
	Collapse,
	Typography,
	Button,
	makeStyles,
	Grid,
	CardContent,
	Divider,
} from '@material-ui/core';
import _ from 'lodash';
import EditIcon from '@material-ui/icons/Edit';
import { useHistory } from 'react-router-dom';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import GetAppIcon from '@material-ui/icons/GetApp';
import clsx from 'clsx';
import { GROUP_ANALYTICS_LABELS } from 'config/flightDataModel';
import { useDispatch } from 'react-redux';
import { exportAllFlightsCsv } from 'redux/actions/mediaBuyActions';

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(2),
	},
	gridItem: {
		padding: theme.spacing(2),
	},
	expand: {
		transform: 'rotate(0deg)',
		marginLeft: 'auto',
		transition: theme.transitions.create('transform', {
			duration: theme.transitions.duration.shortest,
		}),
	},
	expandOpen: {
		transform: 'rotate(180deg)',
	},
}));

const showFields = [
	'id',
	'campaignCode',
	'campaignName',
	'businessUnit',
	'notes',
];

const JobHeader = (props) => {
	const classes = useStyles();
	const { data, canEdit, startExpanded } = props;
	const [expanded, setExpanded] = useState(!!startExpanded);
	const [downloadingAllFlights, setDownloadingAllFlights] = useState(false);

	const hasFlightChips = useMemo(() => {
		for (const key in data.flightChips) {
			if (key !== 'deleted' && data.flightChips[key] > 0) {
				return true;
			}
		}
		return false;
	}, [data.flightChips]);

	const history = useHistory();
	const dispatch = useDispatch();

	const handleExpandClick = () => {
		setExpanded(!expanded);
	};

	const handleDownloadAll = async () => {
		setDownloadingAllFlights(true);
		await dispatch(exportAllFlightsCsv(data));
		setDownloadingAllFlights(false);
	};

	return (
		<Card className={classes.root}>
			<CardHeader
				className={classes.header}
				avatar={<FolderOpenIcon />}
				title={
					<span>
						Job Number:
						<b> {data.jobNumber || ''}</b>
					</span>
				}
				subheader={
					<span>
						Account Name:
						<b> {data.accountName || ''}</b>
					</span>
				}
				action={
					<>
						<Button
							variant='contained'
							startIcon={<GetAppIcon />}
							onClick={handleDownloadAll}
							disabled={downloadingAllFlights || !hasFlightChips}
						>
							{downloadingAllFlights ? 'Loading...' : 'Export All Flights'}
						</Button>
						{canEdit && (
							<Button
								style={{ marginLeft: '20px' }}
								variant='contained'
								children='Edit Job'
								startIcon={<EditIcon />}
								onClick={() => history.push(`/jobs/${data.id}/edit`)}
							/>
						)}
						<Button
							color='primary'
							variant='contained'
							onClick={handleExpandClick}
							aria-expanded={expanded}
							aria-label='show more'
							style={{ marginLeft: '20px' }}
							endIcon={
								<ExpandMoreIcon
									className={clsx(classes.expand, {
										[classes.expandOpen]: expanded,
									})}
								/>
							}
						>
							{!expanded ? 'Show More' : 'Hide'}
						</Button>
					</>
				}
			/>
			<Collapse in={expanded} timeout='auto' unmountOnExit>
				<CardContent>
					<Grid container justify='space-between'>
						{showFields.map((key, idx) => (
							<Grid
								item
								key={idx}
								className={classes.gridItem}
								xs={6}
								lg={2}
								xl
							>
								<Typography variant='body1' className={classes.typography}>
									{_.startCase(key)}:
								</Typography>
								<Typography variant='body1' className={classes.typography}>
									<b>{data[key]}</b>
								</Typography>
							</Grid>
						))}
					</Grid>
					<Grid container justify='flex-start'>
						{data.analytics && (
							<>
								<Grid item xs={12} style={{ marginTop: '30px' }}>
									<Typography variant='h6'>
										<b>Analytics</b>
									</Typography>
									<Divider />
								</Grid>
								{GROUP_ANALYTICS_LABELS.filter(
									({ id }) => !!data.analytics[id]
								).map(({ label, id }, idx) => (
									<Grid
										item
										key={idx}
										className={classes.gridItem}
										xs={6}
										lg={3}
										xl={2}
									>
										<Typography variant='body1' className={classes.typography}>
											{label}:
										</Typography>
										<Typography variant='body1' className={classes.typography}>
											<b>{data.analytics[id]}</b>
										</Typography>
									</Grid>
								))}
							</>
						)}
					</Grid>
				</CardContent>
			</Collapse>
		</Card>
	);
};

JobHeader.defaultProps = {
	data: {
		jobNumber: 404,
	},
};

export default JobHeader;
