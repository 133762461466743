import { ACTION_TYPES } from '../reducers/notificationReducer';
import { ExtendedFirestoreInstance } from 'react-redux-firebase';
import firebaseStatic from 'firebase/app';

export function readNotifications(notificationIds) {
	return async (dispatch, getState, getFirebase) => {
		if (!Array.isArray(notificationIds)) notificationIds = [notificationIds];
		const db: ExtendedFirestoreInstance = getFirebase().firestore();
		const uid: string = getState().firebase.auth.uid;
		if (!uid) return;
		const promises = notificationIds.map((notificationId) => {
			return db
				.runTransaction(async (t) => {
					const notificationRef = db.doc(
						`users/${uid}/notifications/${notificationId}`
					);
					const notification = await t.get(notificationRef);
					if (!notification.exists) return;
					const notificationData = notification.data();
					if (notificationData && notificationData.read) {
						return;
					}
					t.update(notificationRef, { read: true, readAt: Date.now() });
					const userRef = db.doc(`users/${uid}`);
					t.update(userRef, {
						unreadNotifications: firebaseStatic.firestore.FieldValue.increment(
							-1
						),
					});
				})
				.then(
					dispatch({
						type: ACTION_TYPES.MARK_IDS_READ,
						payload: [notificationId],
					})
				);
		});
		await Promise.all(promises);
	};
}

const _createQuery = (
	db: ExtendedFirestoreInstance,
	path: string,
	count: number,
	startAtTime: number,
	startAtRead: boolean
) => {
	return db
		.collection(path)
		.orderBy('read')
		.orderBy('performedAt', 'desc')
		.startAfter(startAtRead, startAtTime)
		.limit(count);
};

export function getMoreNotifications(shouldReset = false, count = 10) {
	return async (dispatch, getState, getFirebase) => {
		const db: ExtendedFirestoreInstance = getFirebase().firestore();
		const uid: string = getState().firebase.auth.uid;
		const { listNotifications, noMore } = getState().notificationFeed;
		const lastNotification = listNotifications[listNotifications.length - 1];
		const startAtTime =
			shouldReset || listNotifications.length < 1
				? Date.now()
				: lastNotification.performedAt;
		const startAtRead =
			shouldReset || listNotifications.length < 1
				? false
				: lastNotification.read;
		const path = `users/${uid}/notifications`;
		let newItems: any[] = [];
		let newNoMore = noMore;
		if (!noMore || shouldReset) {
			const snapshot = await _createQuery(
				db,
				path,
				count,
				startAtTime,
				startAtRead
			).get();
			if (snapshot.empty) newNoMore = true;
			else {
				if (snapshot.size < count) newNoMore = true;
				newItems = snapshot.docs.map((doc) => doc.data());
			}
		}

		if (shouldReset) dispatch({ type: ACTION_TYPES.RESET });
		dispatch({
			type: ACTION_TYPES.ADD_ITEMS,
			payload: {
				newItems,
				noMore: newNoMore,
			},
		});
	};
}

export function setShouldReset(reset: boolean = true) {
	return (dispatch) => {
		dispatch({ type: ACTION_TYPES.SET_SHOULD_RESET, payload: reset });
	};
}
