import { ACTION_TYPES } from '../reducers/authReducer';
import { ALERT_TYPES } from '../reducers/alertsReducer';

export const AUTH_ALERT_MESSAGES = {
	LOGOUT_SUCCESS: 'Successfully logged out.',
	LOGIN_SUCCESS: 'Successfully logged in.',
	LOGIN_INVALID_CREDENTIALS: 'The username or password is invalid.',
	FIREBASE_AUTH_ERROR: 'Something went wrong. Please try again later.',
	NO_LOGOUT: 'Could not logout. Please try again later.',
};

export function setAuthAlert(payload) {
	return { type: ACTION_TYPES.SET_ALERT, payload };
}

const _updateProfileOnLogin = async (firebase, signInResult) => {
	const result = signInResult;
	const { email, phoneNumber, displayName, uid } = result.user;
	await firebase.functions().httpsCallable('users-updateProfileOnSignIn')({
		email,
		phoneNumber,
		displayName,
		uid,
		jobTitle: result.additionalUserInfo.profile.jobTitle,
		firstName: result.additionalUserInfo.profile.givenName,
		lastName: result.additionalUserInfo.profile.surname,
	});
};

export function signInWithMicrosoft() {
	return async (dispatch, getState, getFirebase) => {
		dispatch({ type: ACTION_TYPES.SET_LOADING, payload: true });
		const firebase = getFirebase();
		const provider = new firebase.auth.OAuthProvider('microsoft.com');
		provider.setCustomParameters({
			tenant: '5430cd13-2ad3-4008-9a33-9d08166aea70',
		});
		try {
			const result = await firebase.auth().signInWithPopup(provider);
			dispatch(
				setAuthAlert({
					type: ALERT_TYPES.INFO,
					message: AUTH_ALERT_MESSAGES.LOGIN_SUCCESS,
				})
			);
			_updateProfileOnLogin(firebase, result);
		} catch (error) {
			console.error(error);
			if (error.code === 'auth/account-exists-with-different-credential') {
				dispatch(
					setAuthAlert({
						type: ALERT_TYPES.WARNING,
						message:
							'Log in failed: an account matching these credentials exists with a different authentication method.',
					})
				);
				dispatch({
					type: ACTION_TYPES.ATTEMPT_LINK,
					payload: {
						email: error.email,
						pendingCred: error.credential,
					},
				});
			} else {
				dispatch(
					setAuthAlert({
						type: ALERT_TYPES.ERROR,
						message: 'There was a problem logging you in.',
					})
				);
			}
		}
		dispatch({ type: ACTION_TYPES.SET_LOADING, payload: false });
	};
}

export function testerEmailPasswordLogin(creds) {
	return async (dispatch, getState, getFirebase) => {
		dispatch({ type: ACTION_TYPES.SET_LOADING, payload: true });
		const firebase = getFirebase();
		try {
			await firebase
				.auth()
				.signInWithEmailAndPassword(creds.email, creds.password);
		} catch (error) {
			console.error(error);
			if (
				error.code === 'auth/invalid-email' ||
				error.code === 'auth/wrong-password'
			) {
				dispatch(
					setAuthAlert({
						type: ALERT_TYPES.ERROR,
						message: AUTH_ALERT_MESSAGES.LOGIN_INVALID_CREDENTIALS,
					})
				);
			} else {
				dispatch(
					setAuthAlert({
						type: ALERT_TYPES.ERROR,
						message: AUTH_ALERT_MESSAGES.FIREBASE_AUTH_ERROR,
					})
				);
			}
		}
		dispatch({ type: ACTION_TYPES.SET_LOADING, payload: false });
	};
}

export function legacyLoginForLink(creds) {
	return async (dispatch, getState, getFirebase) => {
		dispatch({ type: ACTION_TYPES.SET_LOADING, payload: true });
		const firebase = getFirebase();
		try {
			const result = await firebase
				.functions()
				.httpsCallable('crowdGetAuthToken')({ ...creds });
			if (result.data.error) throw result.data.error.reason;
			const user = await firebase
				.auth()
				.signInWithCustomToken(result.data.token);
			dispatch(
				setAuthAlert({
					type: ALERT_TYPES.INFO,
					message: AUTH_ALERT_MESSAGES.LOGIN_SUCCESS,
				})
			);
			const linkResult = await user.user.linkWithCredential(
				getState().auth.linkTo.pendingCred
			);
			dispatch(
				setAuthAlert({
					type: ALERT_TYPES.SUCCESS,
					message:
						'Account linked successfully. You can now use Microsoft to sign in normally.',
				})
			);
			dispatch({
				type: ACTION_TYPES.ATTEMPT_LINK,
				payload: null,
			});
			_updateProfileOnLogin(firebase, linkResult);
		} catch (error) {
			console.error(error);
			if (error === 'INVALID_USER_AUTHENTICATION') {
				// crowd invalid credentials
				dispatch(
					setAuthAlert({
						type: ALERT_TYPES.ERROR,
						message: AUTH_ALERT_MESSAGES.LOGIN_INVALID_CREDENTIALS,
					})
				);
			}
		}
		dispatch({ type: ACTION_TYPES.SET_LOADING, payload: false });
	};
}

export function doLogout() {
	return (dispatch, getState, getFirebase) => {
		getFirebase()
			.auth()
			.signOut()
			.then(() => {
				dispatch(
					setAuthAlert({
						type: ALERT_TYPES.INFO,
						message: AUTH_ALERT_MESSAGES.LOGOUT_SUCCESS,
					})
				);
			})
			.catch((err) => {
				console.log(err);
				dispatch(
					setAuthAlert({
						type: ALERT_TYPES.WARNING,
						message: AUTH_ALERT_MESSAGES.NO_LOGOUT,
					})
				);
			});
	};
}
