import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles, Grid, Typography, Button } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import { Skeleton } from '@material-ui/lab';
import { clearResults, paginateResultsTo } from 'redux/actions/algoliaActions';
import Paginator from 'components/molecules/paginator';

const useStyles = makeStyles({
	root: {
		backgroundColor: 'lightgrey',
		padding: '20px',
		marginBottom: '20px',
		paddingBottom: '30px',
	},
	horizontalLine: {
		height: '1px',
		width: '100%',
		backgroundColor: 'black',
		marginBottom: '20px',
	},
	bottomMargin: {
		marginBottom: '20px',
	},
});

const capitalize = (word) => {
	const firstLetter = word.charAt(0);
	const remainingLetters = word.slice(1);
	return firstLetter.toUpperCase() + remainingLetters.toLowerCase();
};

const removeS = (word) => {
	const lowerCased = word.toLowerCase();
	if (lowerCased.charAt(lowerCased.length - 1) === 's')
		return word.slice(0, -1);
	else return word;
};

const _transformHits = (hits) => {
	return hits.map((hit) => {
		console.log(hit);
		const transformed = Object.assign({}, hit);
		Object.keys(hit._highlightResult).forEach((key) => {
			transformed[key] = hit._highlightResult[key].value;
		});
		transformed.displayId = transformed.id;
		transformed.id = hit.objectID;
		return transformed;
	});
};

const AlgoliaSearchResults = (props) => {
	const classes = useStyles();
	const {
		results,
		DisplayComponent,
		loading,
		clearHandler,
		index,
		paginateHandler,
	} = props;
	const [page, setPage] = useState(1);

	const pageChangeCallback = (value) => {
		// paginator is 1-indexed, results pages are zero-indexed
		paginateHandler(value - 1);
		setPage(value);
	};

	const { hits, nbHits, query, nbPages } = results || {};

	// if the query changes, reset to the first page
	useEffect(() => {
		setPage(1);
	}, [query, nbPages]);

	// clear the search results when this component unmounts
	useEffect(() => {
		return () => {
			clearHandler();
		};
	}, [clearHandler]);

	if (!results) return null;

	return (
		<div className={classes.root}>
			{!loading ? (
				<Grid container justify='space-between' wrap={false}>
					<Grid item>
						<Typography className={classes.bottomMargin} variant='h5'>
							{`${nbHits} ${removeS(capitalize(index))}${
								nbHits > 1 || nbHits === 0 ? 's' : ''
							} matching "${query}"`}
						</Typography>
					</Grid>
					<Grid item>
						<Button
							color='secondary'
							startIcon={<CancelIcon />}
							onClick={clearHandler}
						>
							Clear
						</Button>
					</Grid>
				</Grid>
			) : (
				<Typography className={classes.bottomMargin} variant='h5'>
					{`Searching all ${capitalize(index)}...`}
				</Typography>
			)}

			{(loading || (hits && hits.length > 0)) && (
				<div className={classes.horizontalLine} />
			)}
			<Paginator
				page={page}
				count={nbPages}
				changeCallback={pageChangeCallback}
			>
				<Grid container spacing={4}>
					{!loading
						? _transformHits(hits).map((result, idx) => (
								<Grid item key={idx} sm={6} md={4} lg={3} xl={2}>
									<DisplayComponent data={result} />
								</Grid>
						  ))
						: ['', '', '', '', '', ''].map((el, idx) => (
								<Grid item key={idx} sm={6} md={4} lg={3} xl={2}>
									<Skeleton variant='rect' height='200px' animation='wave' />
								</Grid>
						  ))}
				</Grid>
			</Paginator>
		</div>
	);
};

const mapStateToProps = (state, ownProps) => ({
	results: state.algolia.searchResults[ownProps.index],
	loading: state.algolia.loading,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	clearHandler: () => dispatch(clearResults()),
	paginateHandler: (target) =>
		dispatch(paginateResultsTo(ownProps.index, target)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AlgoliaSearchResults);
