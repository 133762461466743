import React from 'react';
import ReactDOM from 'react-dom';
// react redux
import { Provider } from 'react-redux';
import store from './redux/store';
// firebase
import firebase from './config/firebaseConfig';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import { createFirestoreInstance } from 'redux-firestore';
// styling
import CssBaseline from '@material-ui/core/CssBaseline';

// app
import App from './App';

// value overwritten by pipelines
// eslint-disable-next-line
window._env_.BUILD = '291';
window._env_.SEMVER = require('./package.json').version;

const rootElement = document.getElementById('root');

//const unsubscribe = store.subscribe(()=>console.log(store.getState()))

const rrfConfig = {
	userProfile: 'users',
	useFirestoreForProfile: true,
};

const rrfProps = {
	firebase,
	createFirestoreInstance,
	config: rrfConfig,
	dispatch: store.dispatch,
};

ReactDOM.render(
	<Provider store={store}>
		<ReactReduxFirebaseProvider {...rrfProps}>
			<CssBaseline />
			<App />
		</ReactReduxFirebaseProvider>
	</Provider>,
	rootElement
);
