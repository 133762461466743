import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, Chip, Typography, makeStyles } from '@material-ui/core';
import { dbKeyArray, statusObjFromKey } from 'utils/flightConstants';
import FlightIcon from '@material-ui/icons/Flight';

const useStyles = makeStyles((theme) => ({
	label: {},
}));

const defaultDisplayObj = {
	icon: FlightIcon,
	display: ' ',
};

/**
 * Display for counts of a certain flight status on a job
 */
const FlightChip = (props) => {
	const { statusDbKey, count } = props;
	const classes = useStyles();

	const flightStatusObj = statusDbKey
		? statusObjFromKey(statusDbKey)
		: defaultDisplayObj;

	const { display, icon } = flightStatusObj;
	const Icon = icon;

	return (
		<Tooltip
			title={`${count} ${display} Flight${count > 1 || count === 0 ? 's' : ''}`}
		>
			<Chip
				size='small'
				icon={<Icon />}
				label={
					<Typography variant='body2' className={classes.label}>
						{count}
					</Typography>
				}
			/>
		</Tooltip>
	);
};

FlightChip.propTypes = {
	/**
	 * The key to use for the display, based on the flight status constants
	 * Falls back to default flight icon
	 */
	statusDbKey: PropTypes.oneOf(dbKeyArray),
	/**
	 * The number to display on the chip, likely the number of flights in a collection that share a status
	 */
	count: PropTypes.number.isRequired,
};

export default FlightChip;
