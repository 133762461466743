import React from 'react';
import { Paper, Typography, makeStyles, Grid } from '@material-ui/core';
import {
	formatForDisplay,
	formatFieldName,
} from '../../../utils/fieldConverters';
import UserDisplay from '../../atoms/userDisplay';

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(2),
	},
	gridItem: {
		padding: theme.spacing(2),
	},
}));

const showFields = ['statusFlag', 'id', 'lastUpdateAt', 'createdBy'];

const FlightInfoBar = (props) => {
	const classes = useStyles();
	const { flight } = props;

	return (
		<Paper className={classes.root}>
			<Grid container justify='space-evenly'>
				{showFields.map((key, idx) => (
					<Grid item key={idx} className={classes.gridItem} xs={6} lg={3} xl>
						<Typography variant='body1' className={classes.typography}>
							{formatFieldName(key)}
						</Typography>
						<Typography variant='body1' className={classes.typography}>
							{key.includes('By') ? (
								<b>
									<UserDisplay uid={flight[key]} />
								</b>
							) : (
								<b>{formatForDisplay(key, flight[key])}</b>
							)}
						</Typography>
					</Grid>
				))}
			</Grid>
		</Paper>
	);
};

export default FlightInfoBar;
