import React from 'react';
import { connect } from 'react-redux';
import NavBar from '../../molecules/navBar';
import NavDrawer from '../../molecules/navDrawer';
import AlertsController from '../../molecules/AlertsController';
import { ROLES, hasRole } from 'utils/rolesConstants';

export function Navigation(props) {
	// fixes a bug where logging out with the drawer open persists the nav's shifted state
	// OR: drawer can remain open if we have different sidebar navigation when not logged in
	if (props.drawerOpen && !props.uid) props.setDrawerOpen(false);
	const isUser =
		props.uid && props.profile && hasRole(props.profile.roles, ROLES.USER);

	return (
		<div data-test='root'>
			<NavBar {...props} isUser={isUser} data-test='rendered-navbar' />
			{isUser && props.currentRoute && (
				<NavDrawer {...props} data-test='rendered-navdrawer' />
			)}
			<AlertsController />
		</div>
	);
}

const mapStateToProps = (state) => ({
	uid: state.firebase.auth.uid,
	currentRoute: state.routes.currentRoute,
	profile: state.firebase.profile,
});

export default connect(mapStateToProps)(Navigation);
