import React, { useMemo, useState } from 'react';
import {
	Box,
	Chip,
	FormControl,
	FormControlLabel,
	FormHelperText,
	Grid,
	InputLabel,
	MenuItem,
	Paper,
	Select,
	Typography,
	Checkbox,
	Button,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { hasRole, ROLES } from 'utils/rolesConstants';
import { useFirebase } from 'react-redux-firebase';

type User = {
	id: string;
	email: string;
	roles: string[];
	displayName: string;
};

type Props = {
	users: Array<User>;
};

const UserSelect = ({
	options,
	selectedUser,
	setSelectedId,
	label,
	helperText,
	uid,
}: {
	label: string;
	options: User[];
	selectedUser?: User;
	setSelectedId: (id: string) => void;
	helperText?: string;
	uid?: string;
}) => {
	return (
		<FormControl fullWidth>
			<InputLabel id={`${label}-select-label`}>{label}</InputLabel>
			<Select
				id={`${label}-select`}
				labelId={`${label}-select-label`}
				label={label}
				value={selectedUser?.id ?? ''}
				onChange={(e) => setSelectedId(e.target.value as string)}
			>
				<MenuItem value=''>None</MenuItem>
				{options?.map((o) => {
					return (
						<MenuItem key={o.id} value={o.id}>
							{`${o.email + (uid === o.id ? ' (ME)' : '')}`}
						</MenuItem>
					);
				})}
			</Select>
			{!!helperText && <FormHelperText>{helperText}</FormHelperText>}
			{!!selectedUser && (
				<>
					<FormHelperText>{`User ID: ${selectedUser?.id}`}</FormHelperText>
					<FormHelperText>{`Display Name: ${selectedUser?.displayName}`}</FormHelperText>
				</>
			)}
		</FormControl>
	);
};

const UserMergeTool = ({ users }: Props) => {
	const profile = useSelector((state: any) => state.firebase.profile);
	const uid = useSelector((state: any) => state.firebase.auth?.uid);

	const [sourceId, setSourceId] = useState<string>('');
	const [targetId, setTargetId] = useState<string>('');
	const [isAgreed, setAgreed] = useState<boolean>();

	const sourceUser = useMemo(() => users?.find(({ id }) => id === sourceId), [
		users,
		sourceId,
	]);

	const targetUser = useMemo(() => users?.find(({ id }) => id === targetId), [
		users,
		targetId,
	]);

	const firebase = useFirebase();

	const [isLoading, setIsLoading] = useState<boolean>();
	const [responseMessage, setResponseMessage] = useState<string | undefined>();

	const handleMerge = async () => {
		if (window.confirm("Are you sure? This can't be undone.")) {
			const f: (
				data: any
			) => Promise<{
				data: { success?: Boolean; jobs?: number; flights?: number };
			}> = (firebase as any).functions().httpsCallable('users-merge');

			setIsLoading(true);

			try {
				const { data: res } = await f({
					deleteUserId: sourceId,
					mergeIntoUserId: targetId,
				});

				if (res?.success) {
					setResponseMessage(
						`Successfully deleted and merged user. Impacted ${res.jobs ??
							0} Jobs and ${res.flights ?? 0} flights.`
					);
					setSourceId('');
					setTargetId('');
				} else throw new Error();
			} catch (e) {
				console.error(e);
				setResponseMessage('Something went wrong. Please try again.');
			}

			setIsLoading(false);
		}
	};

	if (!hasRole(profile.roles, ROLES.SUPER_ADMIN)) return null;

	return (
		<Box>
			<Typography variant='h5'>Merge Users</Typography>
			<Typography gutterBottom>
				Use this tool to have one user account absorb the data and access of
				another.
			</Typography>
			<Paper>
				<Box sx={{ p: 2 }}>
					{!responseMessage ? (
						<Grid container spacing={3}>
							<Grid item xs={12} sm={6}>
								<UserSelect
									uid={uid}
									label='Source User'
									options={users?.filter(
										({ id }) => id !== targetId && id !== uid
									)}
									selectedUser={sourceUser}
									setSelectedId={setSourceId}
									helperText='The user to delete'
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<UserSelect
									uid={uid}
									label='Target User'
									options={users?.filter(({ id }) => id !== sourceId)}
									selectedUser={targetUser}
									setSelectedId={setTargetId}
									helperText='The user that will inherit the data of the deleted user'
								/>
							</Grid>
							{!!(sourceId && targetId) && (
								<Grid item container xs={12} spacing={2}>
									<Grid item xs={12}>
										<Typography>
											Once merged, the user{' '}
											<Chip size='small' label={sourceUser?.email} /> will be{' '}
											<Typography
												display='inline'
												color='error'
												style={{ fontWeight: 'bold' }}
											>
												deleted
											</Typography>
											. Ownership of their flights and other relevant data will
											be transferred to{' '}
											<Chip size='small' label={targetUser?.email} />.
										</Typography>
									</Grid>
									<Grid item xs={12}>
										<Box sx={{ p: 2 }}>
											<FormControlLabel
												control={
													<Checkbox
														checked={isAgreed}
														onChange={(e) => setAgreed(e.target.checked)}
													/>
												}
												label='I understand this action cannot be reversed'
											/>
										</Box>
									</Grid>
									<Grid item container xs={12} justifyContent='flex-end'>
										<Grid item>
											<Button
												color='secondary'
												variant='contained'
												disabled={!isAgreed || isLoading}
												onClick={handleMerge}
											>
												Delete {sourceUser?.email} and Merge
											</Button>
										</Grid>
									</Grid>
								</Grid>
							)}
						</Grid>
					) : (
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Typography align='center'>{responseMessage}</Typography>
							</Grid>
							<Grid item xs={12} container justifyContent='center'>
								<Grid item>
									<Button
										variant='outlined'
										color='primary'
										onClick={() => {
											setResponseMessage(undefined);
										}}
									>
										OK
									</Button>
								</Grid>
							</Grid>
						</Grid>
					)}
				</Box>
			</Paper>
		</Box>
	);
};

export default UserMergeTool;
