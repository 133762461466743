import { ACTION_TYPES } from '../reducers/routesReducer';

export function setCurrentRoute(route) {
	document.title = route.title;
	return { type: ACTION_TYPES.SET_CURRENT_ROUTE, payload: route };
}

export function setRejectedPath(path) {
	return { type: ACTION_TYPES.SET_REJECTED_PATH, payload: path };
}
