//import react from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';

const UserDisplay = (props) => {
	if (!props.user) return '...';
	let returnValue = props.user.displayName || props.user.email;
	if (!props.hideMe && props.meUid === props.uid) returnValue += ' (ME)';
	return returnValue;
};

const mapStateToProps = (state, ownProps) => ({
	meUid: state.firebase.auth.uid,
	user: state.firestore.data.users && state.firestore.data.users[ownProps.uid],
});

export default compose(
	firestoreConnect((ownProps) => [
		{
			collection: 'users',
			doc: ownProps.uid,
			saveAs: 'users'[ownProps.uid],
		},
	]),
	connect(mapStateToProps)
)(UserDisplay);
