import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import DateTimeChip from 'components/atoms/dateTimeChip';
import { Tooltip, Chip, Grid } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { readNotifications } from 'redux/actions/notificationActions';
import { connect } from 'react-redux';
import { iconFromDbKey } from 'utils/flightConstants';
import FlightIcon from '@material-ui/icons/Flight';
import Folder from '@material-ui/icons/Folder';
import NotificationsIcon from '@material-ui/icons/Notifications';
import parse from 'html-react-parser';

const useStyles = makeStyles((theme) => ({
	root: {
		minWidth: 275,
		width: '100%',
	},
	title: {
		fontSize: 18,
		'& span': {
			fontWeight: 'bold',
		},
	},
	pos: {
		marginBottom: 12,
	},
	unread: {
		borderColor: theme.palette.primary.main,
	},
}));

const getIcon = (type, payload) => {
	if (type.includes('TRANSITION')) {
		return iconFromDbKey(payload.toStatus);
	} else if (type.includes('FLIGHT')) {
		return FlightIcon;
	} else if (type.includes('JOB')) {
		return Folder;
	} else return NotificationsIcon;
};

function NotificationItem(props) {
	const { notification, handleCloseMenu, readNotifications } = props;
	let {
		message,
		url,
		performedAt,
		reasonForReceiving,
		read,
		id,
		type,
		payload,
	} = notification;
	const classes = useStyles();
	const history = useHistory();
	const [loading, setLoading] = useState(false);

	const handleViewNow = () => {
		const goto = new URL(url);
		history.push(goto.pathname + goto.search);
		if (handleCloseMenu) handleCloseMenu();
	};

	const handleReadNotification = () => {
		const goto = new URL(url);
		const fromParam = goto.searchParams.get('notification');
		setLoading(true);
		readNotifications(id || fromParam).then(() => setLoading(false));
	};

	const Avatar = getIcon(type, payload);

	return (
		<Card
			className={clsx(classes.root, {
				[classes.unread]: !read,
			})}
			variant='outlined'
		>
			<CardHeader
				avatar={<Avatar />}
				action={
					!read && (
						<Button
							disabled={loading}
							onClick={handleReadNotification}
							color='primary'
						>
							Mark as Read
						</Button>
					)
				}
			></CardHeader>
			<CardContent>
				<Typography className={classes.title}>{parse(message)}</Typography>
			</CardContent>
			<CardActions>
				<Grid container justify='space-between'>
					<Grid item xs={4}>
						<Button
							size='small'
							onClick={handleViewNow}
							variant='contained'
							color={read ? 'default' : 'primary'}
						>
							View Now
						</Button>
					</Grid>
					<Grid item container spacing={1} xs={8} justify='flex-end'>
						<Grid item>
							<DateTimeChip unix={performedAt / 1000} label='Performed At' />
						</Grid>
						<Grid item>
							<Tooltip title={reasonForReceiving}>
								<Chip
									icon={<HelpIcon />}
									size='small'
									label='Why did I get this?'
								/>
							</Tooltip>
						</Grid>
					</Grid>
				</Grid>
			</CardActions>
		</Card>
	);
}

const mapDispatchToProps = (dispatch) => ({
	readNotifications: (ids) => dispatch(readNotifications(ids)),
});

export default connect(null, mapDispatchToProps)(NotificationItem);
