import React from 'react';
import {
	Drawer,
	Toolbar,
	Divider,
	IconButton,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { NAV_ROUTES } from '../../../config/routes';

function DrawerContent(props) {
	const { currentRoute, history } = props;

	return NAV_ROUTES.LOGGED_IN_DRAWER.map((section, index) => (
		<div key={index}>
			<Divider />
			<List>
				{section.map((route, idx) => (
					<ListItem
						key={idx}
						selected={currentRoute.path.startsWith(route.path)}
						button
						onClick={() => history.push(route.path)}
					>
						<ListItemIcon>
							<route.icon />
						</ListItemIcon>
						<ListItemText primary={route.title} />
					</ListItem>
				))}
			</List>
		</div>
	));
}

function NavDrawer(props) {
	const { currentRoute, drawerOpen, setDrawerOpen } = props;
	const classes = props.useStyles();

	const handleClose = (event) => {
		event.preventDefault();
		// open state managed by parent
		setDrawerOpen(false);
	};

	const history = useHistory();

	return (
		<>
			<Drawer
				data-test='root'
				variant='permanent'
				open={drawerOpen}
				className={clsx(classes.drawer, {
					[classes.drawerOpen]: drawerOpen,
					[classes.drawerClose]: !drawerOpen,
				})}
				classes={{
					paper: clsx({
						[classes.drawerOpen]: drawerOpen,
						[classes.drawerClose]: !drawerOpen,
					}),
				}}
			>
				<Toolbar className={classes.toolbar}>
					<IconButton data-test='close-button' onClick={handleClose}>
						<ChevronLeft />
					</IconButton>
				</Toolbar>
				<DrawerContent
					data-test='drawer-content'
					history={history}
					currentRoute={currentRoute}
				/>
			</Drawer>
		</>
	);
}

export default NavDrawer;
