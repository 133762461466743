import React from 'react';
import { Grid } from '@material-ui/core';
import JobForm from '../../organisms/jobForm';

export const NewJobPage = (props) => {
	return (
		<div data-test='root'>
			<Grid container justify='center'>
				<Grid item>
					<JobForm data-test='media-buy-form' />
				</Grid>
			</Grid>
		</div>
	);
};

export default NewJobPage;
