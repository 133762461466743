import { ACTION_TYPES as ALERT_ACTIONS } from './alertsReducer';

export const ACTION_TYPES = {
	...ALERT_ACTIONS,
	LOGOUT: 'AUTH_LOGOUT',
	SET_LOADING: '_AUTH_SET_LOADING',
	ATTEMPT_LINK: '_AUTH_ATTEMPT_LINK',
};

const initialState = {
	loading: false,
	linkTo: null,
};

export default function authReducer(state = initialState, action) {
	switch (action.type) {
		case ACTION_TYPES.SET_LOADING:
			return {
				...state,
				loading: action.payload,
			};
		case ACTION_TYPES.ATTEMPT_LINK:
			return {
				...state,
				linkTo: action.payload,
			};
		default:
			return state;
	}
}
