import merge from 'lodash.merge';

const ENVIRONMENTS = {
	DEV: 'dev',
	TESTING: 'testing',
	STAGING: 'staging',
	PRODUCTION: 'production',
};
const ENV_CONFIG = {
	dev: {
		host: 'http://localhost:5001',
		name: ENVIRONMENTS.DEV,
		firebase: {
			cloudFunctionsUri:
				'http://localhost:5001/flight-check-testing/us-central1',
			config: {
				apiKey: 'AIzaSyCVMRk-JAW9uZuxaSUZGNaPYB8fUpVH0xw',
				authDomain: 'flight-check-testing.firebaseapp.com',
				databaseURL: 'https://flight-check-testing.firebaseio.com',
				projectId: 'flight-check-testing',
				storageBucket: 'flight-check-testing.appspot.com',
				messagingSenderId: '437253795716',
				appId: '1:437253795716:web:3b184c6209ab8be17737d1',
			},
		},
	},
	testing: {
		name: ENVIRONMENTS.TESTING,
		host: 'https://testing.flightcheck.mediareturnexchange.com',
	},
	staging: {
		name: ENVIRONMENTS.STAGING,
		host: 'https://staging.flightcheck.mediareturnexchange.com',
		firebase: {
			cloudFunctionsUri:
				'https://us-central1-flight-check-staging.cloudfunctions.net',
			config: {
				apiKey: 'AIzaSyDu0Whcg6NtZuAi7BfHfhxY3930mtxxWlA',
				authDomain: 'flight-check-staging.firebaseapp.com',
				databaseURL: 'https://flight-check-staging.firebaseio.com',
				projectId: 'flight-check-staging',
				storageBucket: 'flight-check-staging.appspot.com',
				messagingSenderId: '501484381833',
				appId: '1:501484381833:web:040396db4ca21e9cd18858',
			},
		},
	},
	production: {
		name: ENVIRONMENTS.PRODUCTION,
		host: 'https://flightcheck.mediareturnexchange.com',
		firebase: {
			cloudFunctionsUri:
				'https://us-central1-flight-check-ae37d.cloudfunctions.net',
			config: {
				apiKey: 'AIzaSyCoMd8zmPyov-o3MAbtL0Eazv_ovYLrYJs',
				authDomain: 'flight-check-ae37d.firebaseapp.com',
				databaseURL: 'https://flight-check-ae37d.firebaseio.com',
				projectId: 'flight-check-ae37d',
				storageBucket: 'flight-check-ae37d.appspot.com',
				messagingSenderId: '1004074905935',
				appId: '1:1004074905935:web:53c92d2421fc0c9df4153a',
			},
		},
	},
};

/**
 * returns a configuration object for the requested environment
 * the default properties are set in the DEV configuration and then
 * overridden for any environment specific properties
 * @param {string} environment the environment
 */
function getConfig(environment = ENVIRONMENTS.DEV) {
	if (!ENV_CONFIG.hasOwnProperty(environment))
		environment = ENVIRONMENTS.TESTING;
	let _merged = merge(
		{},
		ENV_CONFIG[ENVIRONMENTS.DEV],
		ENV_CONFIG[environment]
	);
	return _merged;
}

export { ENVIRONMENTS, getConfig };
