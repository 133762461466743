import React from 'react';
import { Grid, makeStyles, Typography, Box } from '@material-ui/core';
import FlightForm from '../../organisms/flightForm';
import FlightInfoBar from '../../molecules/flightInfoBar';
import JobHeader from '../../molecules/jobHeader';
import FlightCalloutPanel from '../../molecules/flightCalloutPanel';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';

const useStyles = makeStyles((theme) => ({
	grid: {
		marginTop: theme.spacing(3),
	},
}));

const EditFlightPage = (props) => {
	const classes = useStyles();
	const { job, flights } = props;

	if (!flights) return 'Loading...';

	const flight = flights.find(
		(elem) => elem.id === props.match.params.flightId
	);

	if (!job || !flight) return 'Loading...';

	return (
		<Box>
			<JobHeader data={job} />
			<div className={classes.grid}>
				<Typography variant='h5'>Flight Information</Typography>
			</div>
			<div className={classes.grid}>
				<FlightInfoBar flight={flight} job={job} />
			</div>
			{flight.rejectionNotes && (
				<div className={classes.grid}>
					<FlightCalloutPanel {...flight} variant='rejected' />
				</div>
			)}
			<Grid container justify='center' className={classes.grid}>
				<FlightForm jobObj={job} remoteData={flight} cloned={false} />
			</Grid>
		</Box>
	);
};

const mapStateToProps = ({ firestore: { data, ordered } }, ownProps) => ({
	job: data[`openJob[${ownProps.match.params.jobId}]`],
	flights: ordered[`openFlights[${ownProps.match.params.jobId}]`],
});

export default compose(
	firestoreConnect((ownProps) => [
		{
			collection: `jobs`,
			doc: `${ownProps.match.params.jobId}`,
			storeAs: `openJob[${ownProps.match.params.jobId}]`,
		},
		{
			collection: `jobs/${ownProps.match.params.jobId}/flights`,
			storeAs: `openFlights[${ownProps.match.params.jobId}]`,
		},
	]),
	connect(mapStateToProps)
)(EditFlightPage);
