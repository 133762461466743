import React from 'react';

function PageNotFound(props) {
	return (
		<div data-test='404-root'>
			<h1>404: Page not found</h1>
		</div>
	);
}

export default PageNotFound;
