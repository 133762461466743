import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import Alert from '../../atoms/alert';

const AlertsController = (props) => {
	const { latestAlert, stack } = props;

	const [open, setOpen] = useState(false);

	const closeCallback = () => setOpen(false);

	useEffect(() => {
		if (latestAlert && latestAlert.message) setOpen(true);
		else setOpen(false);
	}, [latestAlert]);

	return (
		<Alert
			open={open}
			severity={latestAlert.type}
			message={latestAlert.message}
			duration={latestAlert.duration}
			noAutoHide={latestAlert.noAutoHide}
			closeCallback={closeCallback}
			stackSize={stack.length}
		/>
	);
};

const mapStateToProps = (state) => ({
	latestAlert: state.alerts.latest,
	stack: state.alerts.stack,
});

export default connect(mapStateToProps)(AlertsController);
