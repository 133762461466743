import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { readNotifications } from 'redux/actions/notificationActions';

const ReadNotification = ({ readNotifications }) => {
	const { search } = useLocation();
	const [notificationId, setNotificationId] = useState(null);

	useEffect(() => {
		const parsed = queryString.parse(search);
		if (parsed.notification && parsed.notification !== notificationId) {
			setNotificationId(parsed.notification);
		}
	}, [search, setNotificationId, notificationId]);

	useEffect(() => {
		if (notificationId) {
			console.log('Reading notification...');
			readNotifications(notificationId).then(() => {
				console.log('Notification Read');
			});
		}
	}, [notificationId, readNotifications]);

	return null;
};

const mapDispatchToProps = (dispatch) => ({
	readNotifications: (id) => dispatch(readNotifications(id)),
});

export default connect(null, mapDispatchToProps)(ReadNotification);
