import React, { useState } from 'react';
import {
	Card,
	CardHeader,
	CardContent,
	makeStyles,
	CardActionArea,
	Grid,
} from '@material-ui/core';
import Folder from '@material-ui/icons/Folder';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import FlightChip from 'components/atoms/flightChip';
import { visibleDbKeyArray } from 'utils/flightConstants';
import DateTimeChip from 'components/atoms/dateTimeChip';

//const _sortKeys = (a, b) => dbKeyArray.indexOf(a) - dbKeyArray.indexOf(b);
//const _filterKeys = (k, obj) => visibleDbKeyArray.includes(k) && obj[k] > 0;

const getVisibleFlightChips = (obj) => {
	let returnObj = {};
	if (!obj) return returnObj;
	Object.keys(obj).forEach((key) => {
		if (visibleDbKeyArray.includes(key)) returnObj[key] = obj[key];
	});
	return returnObj;
};

const sumObjectValues = (obj) => {
	const values = Object.values(obj);
	if (values.length < 1) return 0;
	return values.reduce((a, b) => a + b);
};

const useStyles = makeStyles((theme) => ({
	a: {
		textDecoration: 'none',
	},
	card: {
		height: '100%',
		cursor: 'pointer',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		'& em': {
			borderBottom: `${theme.palette.primary.main} 1px solid`,
			color: theme.palette.primary.main,
			fontWeight: 'bold',
			fontStyle: 'normal',
		},
	},
	header: {
		borderBottom: `${theme.palette.divider} 1px solid`,
	},
	content: {
		'& .id': {
			fontSize: theme.typography.fontSize.valueOf(),
			textAlign: 'left',
			paddingTop: theme.spacing(1),
			color: theme.palette.text.disabled,
		},
		'& em': {
			borderBottom: `${theme.palette.primary.main} 1px solid`,
			color: theme.palette.primary.main,
			fontWeight: 'bold',
			fontStyle: 'normal',
		},
	},
	cardAction: {
		padding: '5px',
	},
}));

const JobFolder = (props) => {
	const { data } = props;
	const {
		campaignCode,
		campaignName,
		accountName,
		businessUnit,
		jobNumber,
		notes,
		flightChips,
		id,
		displayId,
	} = data;
	const classes = useStyles();

	const [hovered, setHovered] = useState(false);

	return (
		<Link to={`/jobs/${data.id}`} className={classes.a}>
			<Card
				className={classes.card}
				onMouseEnter={() => setHovered(true)}
				onMouseLeave={() => setHovered(false)}
			>
				<CardHeader
					className={classes.header}
					avatar={hovered ? <FolderOpenIcon /> : <Folder />}
					title={parse(jobNumber || '')}
					subheader={parse(accountName || '')}
				/>
				<CardContent className={classes.content}>
					<span className='id'>
						<b>Id: </b> {parse(displayId || id || '')}
					</span>
					<br />
					<b>Campaign Code:</b> {parse(campaignCode || '')} <br />
					<b>Campaign Name:</b> {parse(campaignName || '')} <br />
					<b>Business Unit:</b> {parse(businessUnit || '')} <br />
					<b>Notes:</b> {parse(notes || '')}
					<Grid container justify='center' className={'id'}>
						<Grid item></Grid>
					</Grid>
				</CardContent>
				<CardActionArea className={classes.cardAction}>
					<Grid container spacing={1} justify='space-between'>
						<Grid item>
							<FlightChip
								count={sumObjectValues(getVisibleFlightChips(flightChips))}
							/>
						</Grid>
						<Grid item>
							<DateTimeChip
								unix={data.lastUpdateAt / 1000}
								label='Last Updated'
							/>
						</Grid>
					</Grid>
				</CardActionArea>
			</Card>
		</Link>
	);
};

export default JobFolder;
