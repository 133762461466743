import React, { useState, useEffect } from 'react';
import {
	Paper,
	Table,
	TableHead,
	TableCell,
	TableBody,
	TableRow,
	Checkbox,
	makeStyles,
	Select,
	MenuItem,
	TableContainer,
	TablePagination,
} from '@material-ui/core';
import { formatFieldName } from '../../../utils/fieldConverters';
import { FLIGHT_FIELDS_FLAT } from '../../../config/flightDataModel';
import validateForm from '../../../utils/validateForm';
import { connect } from 'react-redux';
import { visibleDbKeyArray, statusObjFromKey } from 'utils/flightConstants';
import FlightTableToolbar from 'components/molecules/FlightTableToolbar';

import { useSelector } from 'react-redux';
import { getQueryId, getFlights } from 'redux/actions/flightTableActions';
import FlightTableRow from 'components/molecules/FlightTableRow';
import FilterListIcon from '@material-ui/icons/FilterList';

const useStyles = makeStyles((theme) => ({
	paper: {
		padding: theme.spacing(2),
		marginBottom: theme.spacing(4),
	},
	headCell: {
		fontWeight: 800,
	},
	bulkButton: {
		marginTop: theme.spacing(2),
		marginRight: theme.spacing(2),
	},
	tableContainer: {
		maxHeight: 'none',
	},
}));

const filteredFlightChipCount = (filterRules, flightChips) => {
	if (!flightChips) return 0;
	const statusFlagRule = filterRules.find(
		(rule) => rule.field === 'statusFlag'
	);
	let count = 0;
	for (const key of Object.keys(flightChips)) {
		if (!statusFlagRule.matches.includes(key)) continue;
		count += flightChips[key];
	}
	return count;
};

const availableHeaders = [
	'statusFlag',
	'parentJob',
	'id',
	...FLIGHT_FIELDS_FLAT.map(({ id }) => id),
	'createdBy',
	'lastUpdateBy',
	'createdAt',
	'lastUpdateAt',
];

const createEmptyArray = (size) =>
	Array.apply(null, Array(size)).map(() => null);

const FlightTable = (props) => {
	const classes = useStyles();
	const { jobObj, getFlights, loading, tenant } = props;
	const [perPage, setPerPage] = useState(25);
	const [pageNumber, setPageNumber] = useState(0);

	const [selected, setSelected] = useState([]);
	const [headers, setHeaders] = useState([
		'statusFlag',
		'channel',
		'platform',
		'startDate',
		'endDate',
		'createdBy',
	]);
	const [filterRules, setFilterRules] = useState([
		{
			field: 'statusFlag',
			matches: [...visibleDbKeyArray],
			type: 'include',
		},
	]);

	const [sortingRules, setSortingRules] = useState([
		{ field: headers[0], direction: 'asc' },
	]);

	const handleNewSortingRule = (field, direction) => {
		if (loading) return;
		setSortingRules([{ field, direction }]);
	};

	const [totalFlights, setTotalFlights] = useState(
		filteredFlightChipCount(
			filterRules,
			jobObj ? jobObj.flightChips : tenant.flightChips
		)
	);

	useEffect(() => {
		setTotalFlights(
			filteredFlightChipCount(
				filterRules,
				jobObj ? jobObj.flightChips : tenant.flightChips
			)
		);
	}, [jobObj, tenant, filterRules, setTotalFlights]);

	useEffect(() => {
		setSelected([]);
		getFlights({
			filterRules: filterRules,
			sortRules: sortingRules,
			perPage: perPage,
			pageNumber: pageNumber,
			jobId: jobObj ? jobObj.id : null,
			clearOtherPages: true,
		});
	}, [
		filterRules,
		sortingRules,
		perPage,
		pageNumber,
		jobObj,
		tenant,
		getFlights,
	]);

	const flightsFromQuery = useSelector((state) => {
		const queryId = getQueryId(
			filterRules,
			sortingRules,
			perPage,
			jobObj ? jobObj.id : null
		);
		if (!state.flightTables[queryId]) return createEmptyArray(perPage);
		if (!state.flightTables[queryId][pageNumber])
			return createEmptyArray(perPage);
		return state.flightTables[queryId][pageNumber].flights;
	});

	const flightsLoaded =
		flightsFromQuery && flightsFromQuery[0] && flightsFromQuery[0].id;

	const [validatedFlights, setValidatedFlights] = useState(
		flightsLoaded
			? flightsFromQuery
					.filter(
						(f) => Object.keys(validateForm(f, FLIGHT_FIELDS_FLAT)).length === 0
					)
					.map((f) => f.id)
			: []
	);

	useEffect(() => {
		if (loading) return;
		const ids = flightsLoaded
			? flightsFromQuery
					.filter(
						(f) => Object.keys(validateForm(f, FLIGHT_FIELDS_FLAT)).length === 0
					)
					.map((f) => f.id)
			: [];
		setValidatedFlights(ids);
		setSelected([]);
	}, [
		setSelected,
		setValidatedFlights,
		flightsFromQuery,
		loading,
		flightsLoaded,
	]);

	const toggleSelectAll = () => {
		if (selected.length !== 0) setSelected([]);
		else
			setSelected(() => {
				const newVal = [];
				flightsFromQuery.forEach(({ id }) => newVal.push(id));
				return newVal;
			});
	};

	const toggleSelect = (flight) => {
		const idx = selected.indexOf(flight.id);
		if (idx === -1)
			setSelected((prev) => {
				const newVal = [...prev];
				newVal.push(flight.id);
				return newVal;
			});
		else
			setSelected((prev) => {
				const newVal = [...prev];
				newVal.splice(idx, 1);
				return newVal;
			});
	};

	const handleStatusFilterChange = (e) => {
		if (loading) return;
		if (e.target.value.length < 1) return;
		setPageNumber(0);
		setFilterRules([
			{
				field: 'statusFlag',
				matches: [...e.target.value],
				type: 'include',
			},
		]);
	};

	const handleHeaderChange = ({ target: value }, idx) => {
		value = value.value;
		setHeaders((prev) => {
			const newHeaders = [...prev];
			newHeaders[idx] = value;
			return newHeaders;
		});
	};

	const handlePageNumberChange = (e, newPage) => {
		if (loading) return;
		setPageNumber(newPage);
	};

	const handlePerPageChange = ({ target: value }) => {
		if (loading) return;
		setPageNumber(0);
		setPerPage(value.value);
	};

	return (
		<>
			<Paper className={classes.paper}>
				<>
					<FlightTableToolbar
						headers={headers}
						sortRule={sortingRules[0]}
						count={totalFlights}
						jobObj={jobObj}
						flightObjArr={
							flightsLoaded &&
							flightsFromQuery.filter((f) => selected.includes(f.id))
						}
						validatedIdArr={validatedFlights}
						handleSortChange={handleNewSortingRule}
					/>
					<TableContainer className={classes.tableContainer}>
						<Table stickyHeader={true} size='small'>
							<TableHead>
								<TableRow>
									<TableCell padding='checkbox'>
										<Checkbox
											indeterminate={
												selected.length > 0 &&
												selected.length < flightsFromQuery.length
											}
											checked={
												selected.length === flightsFromQuery.length &&
												selected.length > 0
											}
											onClick={() => toggleSelectAll()}
										/>
									</TableCell>
									{headers.map((field, idx) => {
										return (
											<TableCell key={idx} className={classes.headCell}>
												{idx !== 0 ? (
													<Select
														value={field}
														onChange={(e) => handleHeaderChange(e, idx)}
														disabled={idx === 0}
													>
														{availableHeaders.map((h, i) => (
															<MenuItem key={i} value={h}>
																{formatFieldName(h)}
															</MenuItem>
														))}
													</Select>
												) : (
													<Select
														multiple
														value={
															filterRules.find(
																(rule) => rule.field === 'statusFlag'
															).matches
														}
														renderValue={() => (
															<b style={{ marginRight: '15px' }}>Status</b>
														)}
														IconComponent={FilterListIcon}
														variant='outlined'
														onChange={handleStatusFilterChange}
													>
														{visibleDbKeyArray.map((key, idx) => {
															const statusObj = statusObjFromKey(key);
															return (
																<MenuItem value={key} key={idx}>
																	<Checkbox
																		checked={filterRules
																			.find(
																				(rule) => rule.field === 'statusFlag'
																			)
																			.matches.includes(key)}
																	/>
																	{statusObj.display}
																	<statusObj.icon
																		style={{ marginLeft: '15px' }}
																	/>
																</MenuItem>
															);
														})}
													</Select>
												)}
											</TableCell>
										);
									})}
									<TableCell></TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{flightsFromQuery.map((flight, idx) => {
									const isSelected = flight
										? selected.indexOf(flight.id) !== -1
										: false;
									const isValid = flight
										? validatedFlights.includes(flight.id)
										: false;

									return (
										<FlightTableRow
											key={idx}
											flight={flight}
											isSelected={isSelected}
											isValid={isValid}
											toggleSelect={toggleSelect}
											headers={headers}
											validatedFlights={validatedFlights}
										/>
									);
								})}
							</TableBody>
						</Table>
					</TableContainer>
					<TablePagination
						component='div'
						rowsPerPage={perPage}
						rowsPerPageOptions={[10, 25, 50]}
						count={totalFlights}
						page={pageNumber}
						onChangePage={handlePageNumberChange}
						onChangeRowsPerPage={handlePerPageChange}
						disabled={true}
					/>
				</>
			</Paper>
		</>
	);
};

const mapStateToProps = (state) => ({
	uid: state.firebase.auth.uid,
	profile: state.firebase.profile,
	loading: state.flightTables.loading,
	tenant: state.firestore.data.tenant,
});

const mapDispatchToProps = (dispatch) => ({
	getFlights: (args) => dispatch(getFlights(args)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FlightTable);
