/* eslint-disable no-case-declarations */
import { ACTION_TYPES as ALERT_ACTIONS } from './alertsReducer';

export const ACTION_TYPES = {
	...ALERT_ACTIONS,
	SET_FLIGHTS: '_FLIGHT_TABLE_SET_FLIGHTS',
	CLEAR_OTHER_PAGES: '_FLIGHT_TABLE_CLEAR_OTHER_PAGES',
	RESET_ALL: '_FLIGHT_TABLE_RESET_ALL',
	SET_LOADING: '_FLIGHT_TABLE_SET_LOADING',
};

const initialState = {
	loading: false,
};

export default function FlightTableReducer(state = initialState, action) {
	switch (action.type) {
		case ACTION_TYPES.SET_FLIGHTS:
			return {
				...state,
				[action.payload.queryId]: {
					...state[action.payload.queryId],
					[action.payload.pageNumber]: {
						flights: action.payload.flights,
						lastVisible: action.payload.lastVisible,
					},
				},
			};
		case ACTION_TYPES.CLEAR_OTHER_PAGES:
			const { queryId, currentPage } = action.payload;
			const newState = { ...state };
			if (!state[queryId]) return newState;
			const prevPages = Object.keys(state[queryId]).filter(
				(pageNumber) => pageNumber !== currentPage
			);
			prevPages.forEach((page) => {
				delete newState[queryId][page];
			});
			return newState;
		case ACTION_TYPES.SET_LOADING:
			return {
				...state,
				loading: action.payload,
			};
		case ACTION_TYPES.RESET_ALL:
			return initialState;
		default:
			return state;
	}
}
