/* eslint-disable indent */
/* eslint-disable react/no-children-prop */
import React, { useState } from 'react';
import {
	FLIGHT_FIELDS_FLAT,
	FLIGHT_DEFAULTS,
} from '../../../config/flightDataModel';
import MediaBuyFormField from '../../atoms/mediaBuyFormField';
import {
	Button,
	Grid,
	Typography,
	CircularProgress,
	Paper,
	makeStyles,
	TextField,
} from '@material-ui/core';
import cloneDeep from 'lodash.clonedeep';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import {
	updateDraftFlights,
	transitionFlights,
} from '../../../redux/actions/mediaBuyActions.ts';
import validateForm from '../../../utils/validateForm';
import _passesDependencies from '../../../utils/passesDependencies';

const useStyles = makeStyles((theme) => ({
	paper: {
		padding: theme.spacing(2),
		maxWidth: null,
	},
	button: {
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(2),
		marginLeft: theme.spacing(2),
	},
	header: {
		marginBottom: theme.spacing(2),
	},
	rejectionNotes: {
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(3),
	},
}));

const cleanRemote = (data, cloned) => {
	if (!cloned) return cloneDeep(data);
	const returnObj = {};
	Object.keys(FLIGHT_DEFAULTS).forEach((key) => {
		if (data[key]) returnObj[key] = data[key];
	});
	return returnObj;
};

const FlightForm = (props) => {
	const classes = useStyles();
	const { remoteData, jobObj, cloned, readOnly, review } = props;

	// if remoteData is passed to the form, use it. Otherwise, use the defaults
	// if cloned, non-field data will be cleaned via cleanRemote() to prevent carryover of non-form properties
	const [formValues, setFormValues] = useState(
		remoteData ? cleanRemote(remoteData, cloned) : cloneDeep(FLIGHT_DEFAULTS)
	);
	const [formErrors, setFormErrors] = useState(
		remoteData ? validateForm(formValues, FLIGHT_FIELDS_FLAT) : {}
	);

	const History = useHistory();

	const handleChange = ({ target }) => {
		let { id, value, name } = target;
		if (!id && !!name) id = name;

		const type = FLIGHT_FIELDS_FLAT.find(({ id: _id }) => _id === id)?.type;
		if (type === 'number') {
			value = parseFloat(value);
		}

		let newState = {};

		setFormValues((prev) => {
			Object.assign(newState, prev);
			newState[id] = value;
			return newState;
		});

		if (newState[id] && formErrors[id])
			setFormErrors(validateForm(newState, FLIGHT_FIELDS_FLAT));
	};

	const toggleFieldSelected = (fieldName) => {
		const newArray = formValues.rejectedFields
			? [...formValues.rejectedFields]
			: [];
		const idx = newArray.indexOf(fieldName);
		if (idx === -1) newArray.push(fieldName);
		else newArray.splice(idx, 1);
		handleChange({ target: { id: 'rejectedFields', value: newArray } });
		// remove the rejectedNotes if the
		if (!newArray || newArray.length < 1)
			handleChange({ target: { id: 'rejectionNotes', value: '' } });
	};

	const handleDateChange = (id, value) => {
		handleChange({ target: { id, value } });
	};

	const handleSave = () => {
		props.updateDraftFlights(jobObj, formValues).then(() => {
			History.push(`/jobs/${jobObj.id}`);
		});
	};

	const handleReject = () => {
		props.rejectFlights(jobObj, [formValues]).then(() => {
			History.push(`/jobs/${jobObj.id}`);
		});
	};

	const handleApprove = () => {
		props.approveFlights(jobObj, [formValues]).then(() => {
			History.push(`/jobs/${jobObj.id}`);
		});
	};

	const _transformDynamicField = (field, formValues) => {
		const newField = cloneDeep(field);
		// speciic rules for platform field
		if (field.id === 'platform') {
			newField.options = field.options[formValues.channel];
			newField.multiple = !field.multiple.whenNotChannel.includes(
				formValues.channel
			);
			newField.disabled = false;
		}
		if (field.id === 'creativeUnit') {
			if (Array.isArray(formValues.platform)) {
				newField.multiple = field.multiple.whenNotPlatform.some(
					(check) => !formValues.platform.includes(check)
				);
			} else {
				newField.multiple = !field.multiple.whenNotPlatform.includes(
					formValues.platform
				);
			}
		}
		// if a field is a select and only has one available option, force that to be picked
		if (newField.options && newField.options.length === 1) {
			const forcedValue = newField.options[0].value;
			if (formValues[field.id] !== forcedValue) {
				handleChange({ target: { name: field.id, value: forcedValue } });
			}
			newField.disabled = true;
		}
		// delete isDynamic here as it does not need to be passed down
		delete newField.isDynamic;
		return newField;
	};

	return (
		<Paper className={classes.paper}>
			{!readOnly && (
				<Typography variant='h6' className={classes.header}>
					<b>{remoteData ? 'Edit' : 'Add'} flight information</b>
					<br />
					All fields are required (well not all of them...)
				</Typography>
			)}
			<Grid container spacing={3}>
				{FLIGHT_FIELDS_FLAT.map((field, idx) => {
					field.name = field.id;
					if (field.dependencies && !_passesDependencies(field, formValues)) {
						// clear the value of the field on the form if necassary because it's not available in the form
						if (formValues[field.id])
							handleChange({ target: { id: field.id, value: '' } });
						return null;
					}
					if (field.isDynamic)
						field = _transformDynamicField(field, formValues);

					return (
						<Grid key={idx} item xs={12} md={6} lg={4} xl={3}>
							<MediaBuyFormField
								readOnly={readOnly}
								parentField={{ id: null }}
								showCheckBox={!!review}
								rejected={
									!!(
										formValues.rejectedFields &&
										formValues.rejectedFields.indexOf(field.name) > -1
									)
								}
								field={field}
								changedFromRemote={
									remoteData &&
									remoteData[field.name] !== formValues[field.name]
								}
								fieldValue={formValues[field.id]}
								fieldError={formErrors[field.id]}
								formValues={formValues}
								toggleFieldSelected={toggleFieldSelected}
								handleChange={handleChange}
								handleDateChange={handleDateChange}
							/>
						</Grid>
					);
				})}
			</Grid>
			{props.loading ? (
				<CircularProgress />
			) : (
				<div className={classes.buttonContainer}>
					{!readOnly && (
						<Button
							className={classes.button}
							children={'Cancel'}
							onClick={() => History.push(`/jobs/${jobObj.id}`)}
						/>
					)}
					{!review && !readOnly && (
						<>
							<Button
								className={classes.button}
								color='primary'
								variant='contained'
								children={remoteData && !cloned ? 'Save Draft' : 'Create Draft'}
								onClick={() => handleSave()}
							/>
						</>
					)}
					{review && (
						<>
							{formValues.rejectedFields &&
								formValues.rejectedFields.length > 0 && (
									<TextField
										variant='filled'
										id='rejectionNotes'
										name='rejectionNotes'
										fullWidth
										multiline
										label='Rejection Notes'
										value={formValues.rejectionNotes}
										onChange={handleChange}
										required={true}
										className={classes.rejectionNotes}
									/>
								)}

							<Button
								className={classes.button}
								children={'Cancel'}
								onClick={() => History.push(`/jobs/${jobObj.id}`)}
							/>
							<Button
								className={classes.button}
								color='secondary'
								variant='contained'
								children={'Reject'}
								onClick={() => handleReject()}
								disabled={
									!formValues.rejectedFields ||
									formValues.rejectedFields.length < 1 ||
									!formValues.rejectionNotes
								}
							/>
							<Button
								className={classes.button}
								color='primary'
								variant='contained'
								children={'Approve'}
								onClick={() => handleApprove()}
								disabled={
									(formValues.rejectedFields &&
										formValues.rejectedFields.length > 0) ||
									formValues.rejectionNotes
								}
							/>
						</>
					)}
				</div>
			)}
		</Paper>
	);
};

const mapStateToProps = (state) => ({
	loading: state.mediaBuy.loading,
});

const mapDispatchToProps = (dispatch) => ({
	updateDraftFlights: (jobObj, flightObj) =>
		dispatch(updateDraftFlights(jobObj, [flightObj])),
	rejectFlights: (jobObj, flightsObjArr) =>
		dispatch(transitionFlights(jobObj, flightsObjArr, 'rejected')),
	approveFlights: (jobObj, flightsObjArr) =>
		dispatch(transitionFlights(jobObj, flightsObjArr, 'approved')),
});

export default connect(mapStateToProps, mapDispatchToProps)(FlightForm);
