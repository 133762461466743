import React from 'react';
import Typography from '@material-ui/core/Typography';
import {
	iconFromDbKey,
	colorFromDbKey,
	displayFromDbKey,
} from 'utils/flightConstants';
import { fromUnixTime, format } from 'date-fns';
import _ from 'lodash';
import UserDisplay from 'components/atoms/userDisplay';

export const formatForDisplay = (name, value) => {
	try {
		// arrays should be a string with commas seperating elements
		if (Array.isArray(value)) return value.join(', ');
		// dates should be human readable
		if (
			name.toLowerCase().includes('updateat') ||
			name.toLowerCase().includes('createdat')
		)
			return format(fromUnixTime(value / 1000), 'MM/dd/yyyy, hh:mm a');
		if (name.toLowerCase().includes('by')) return <UserDisplay uid={value} />;
		if (name.toLowerCase().includes('date'))
			return format(fromUnixTime(value / 1000), 'MM/dd/yyyy');

		// capitalize status
		if (name === 'statusFlag') {
			const Icon = iconFromDbKey(value);

			return (
				<Typography
					variant='body1'
					style={{
						display: 'inline-flex',
						verticalAlign: 'middle',
						fontWeight: 'bold',
					}}
				>
					<Icon style={{ marginRight: '7px', color: colorFromDbKey(value) }} />
					{displayFromDbKey(value)}
				</Typography>
			);
		}
	} catch (e) {
		console.error(e);
		return 'N/A';
	}
	// return the value if the arguments dont trigger a rule or throw an error
	return value;
};

const nameReplacers = {
	parentJob: 'Job',
	statusFlag: 'Status',
	measuredKPIs: 'Measured KPIs',
};

export const formatFieldName = (name) => {
	if (nameReplacers[name]) return nameReplacers[name];
	return _.startCase(name);
};
