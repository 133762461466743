import _passesDependencies from './passesDependencies';
const validateForm = (values, fields) => {
	const formErrorObj = {};
	fields.forEach((field) => {
		if (field.map) {
			const subFieldsErrors = validateForm(values[field.id], field.fields);
			if (Object.entries(subFieldsErrors).length > 0) {
				formErrorObj[field.id] = subFieldsErrors;
			}
		} else if (field.required) {
			if (_passesDependencies(field, values)) {
				if (
					!values[field.id] ||
					(Array.isArray(values[field.id]) && !values[field.id].length)
				) {
					formErrorObj[field.id] = {
						type: 'required',
						message: 'This field is required for submission.',
					};
				}
			}
		}
	});
	return formErrorObj;
};

export default validateForm;
