import React from 'react';
import { Fab, Zoom, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	button: {
		position: 'fixed',
		bottom: theme.spacing(3),
		right: theme.spacing(3),
	},
	fab: {},
	icon: {
		marginRight: 10,
	},
}));

const AnimatedFab = (props) => {
	const classes = useStyles();

	const handleClick = (e) => {
		e.preventDefault();
		props.action();
	};

	return (
		<Zoom in={true} timeout={500} data-test='root' className={classes.button}>
			<Fab
				className={classes.fab}
				variant='extended'
				color='primary'
				onClick={handleClick}
				data-test='rendered-fab'
			>
				{props.icon && (
					<props.icon className={classes.icon} data-test='rendered-icon' />
				)}
				<span data-test='rendered-text'>{props.text}</span>
			</Fab>
		</Zoom>
	);
};

export default AnimatedFab;
