import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, Chip, Typography, makeStyles } from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { fromUnixTime, format, isToday } from 'date-fns';

const useStyles = makeStyles((theme) => ({
	label: {},
}));

/**
 * Show a human readable date from a unix timestamp
 */
const DateTimeChip = (props) => {
	const { unix, label } = props;
	const classes = useStyles();

	const d = fromUnixTime(unix);
	const today = isToday(d);
	const longFormatted = format(d, 'MM/dd/yyyy h:mm aaaa');
	let shortFormatted = today ? format(d, 'h:mm aaaa') : format(d, 'MM/dd/yyyy');

	return (
		<Tooltip title={`${label ? label + ': ' : ''} ${longFormatted}`}>
			<Chip
				size='small'
				icon={<AccessTimeIcon />}
				label={
					<Typography variant='body2' className={classes.label}>
						{shortFormatted}
					</Typography>
				}
			/>
		</Tooltip>
	);
};

DateTimeChip.propTypes = {
	/**
	 * The unix timestamp
	 */
	unix: PropTypes.number.isRequired,
	/**
	 * A description of what the timestamp is for
	 */
	label: PropTypes.string,
};

export default DateTimeChip;
