import React, { useState, useEffect } from 'react';
import {
	Paper,
	Table,
	TableHead,
	TableCell,
	TableBody,
	TableRow,
	Typography,
	makeStyles,
	CircularProgress,
	Select,
	MenuItem,
} from '@material-ui/core';
import { connect } from 'react-redux';
import {
	getHighestRole,
	ROLES,
	getRoleFromStrength,
	getDbRoleArray,
} from 'utils/rolesConstants';
import { useFirebase } from 'react-redux-firebase';

const useStyles = makeStyles((theme) => ({
	tableHead: {
		fontWeight: 800,
	},
	anchor: {
		color: theme.palette.primary.main,
	},
}));

const tableColumns = [
	{ title: '', prop: 'profilepicture' },
	{ title: 'Name', prop: 'displayName' },
	{ title: 'Title', prop: 'jobTitle' },
	{ title: 'Email', prop: 'email' },
	{ title: 'Role', prop: 'roles' },
];

const EditableRoleCell = ({ uid, valueFromDb, myRoleStrength }) => {
	const [value, setValue] = useState(getHighestRole(valueFromDb).strength);
	const [loading, setLoading] = useState(false);
	const firebase = useFirebase();

	useEffect(() => {
		setValue(getHighestRole(valueFromDb).strength);
	}, [valueFromDb]);

	const handleChange = async ({ target: { value } }) => {
		setLoading(true);
		setValue(getRoleFromStrength(value).strength);
		const changeUserRole = firebase
			.functions()
			.httpsCallable('users-changeUserRole');
		await changeUserRole({
			uid,
			roles: getDbRoleArray(getRoleFromStrength(value)),
		});
		setLoading(false);
	};

	return (
		<TableCell>
			<Select onChange={handleChange} value={value} disabled={loading}>
				{Object.values(ROLES)
					.filter(({ strength }) => strength < myRoleStrength)
					.map(({ strength, display }, idx) => (
						<MenuItem key={idx} value={strength}>
							{display}
						</MenuItem>
					))}
			</Select>
			{loading && <CircularProgress size={20} />}
		</TableCell>
	);
};

const UserTable = (props) => {
	const { users, title, uid, profile } = props;
	const classes = useStyles();

	const myRoleStrength = getHighestRole(profile.roles).strength;

	return (
		<>
			<Typography variant='h5'>{title}</Typography>
			<Paper>
				<Table>
					<TableHead>
						<TableRow>
							{tableColumns.map((c) => (
								<TableCell className={classes.tableHead} key={c.prop}>
									{c.title}
								</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{users.map((user, idx) => (
							<TableRow key={idx}>
								{tableColumns.map(({ prop }) => {
									let val;
									if (prop === 'email')
										val = (
											<a
												className={classes.anchor}
												href={`mailto:${user[prop]}`}
											>
												{user[prop]}
											</a>
										);
									else if (prop === 'displayName' && user.id === uid) {
										val = `${user[prop] || ''} (ME)`;
									} else if (prop === 'roles') {
										val = getHighestRole(user[prop]).display;
									} else val = user[prop];
									if (
										prop !== 'roles' ||
										myRoleStrength <= getHighestRole(user.roles).strength
									) {
										return <TableCell key={prop}>{val}</TableCell>;
									} else {
										return (
											<EditableRoleCell
												myRoleStrength={myRoleStrength}
												valueFromDb={user[prop]}
												prop={prop}
												uid={user.id}
												key={user.id}
											/>
										);
									}
								})}
							</TableRow>
						))}
					</TableBody>
				</Table>
			</Paper>
		</>
	);
};

const mapStateToProps = (state) => ({
	uid: state.firebase.auth.uid,
	profile: state.firebase.profile,
});

export default connect(mapStateToProps)(UserTable);
