// pages
import Home from '../components/pages/home';
import Login from '../components/pages/login';
import ProfilePage from '../components/pages/profile';
import JobsPage from '../components/pages/jobs';
import NewFlightPage from '../components/pages/newFlight';
import EditFlightPage from '../components/pages/editFlight';
import NewJobPage from '../components/pages/newJob';
import EditJobPage from '../components/pages/editJob';
import PageNotFound from '../components/pages/pageNotFound';
import JobPage from '../components/pages/job';
import FlightPage from '../components/pages/flight';
import ReviewFlightPage from '../components/pages/reviewFlight';
import UsersPage from '../components/pages/users';
import FlightsPage from 'components/pages/flights';
import PendingUser from 'components/pages/pendingUser';
// icons
import HomeIcon from '@material-ui/icons/Home';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import FlightIcon from '@material-ui/icons/Flight';
import Folder from '@material-ui/icons/Folder';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';

export const GUARD_TYPES = {
	WITH_AUTH: 'WITH_AUTH', //protected against logged out users
	WITHOUT_AUTH: 'WITHOUT_AUTH', //protected against logged in users
	CAN_REVIEW_FLIGHT: 'WITH_ADMIN_JOB', //protected against users that can't review a flight
	CAN_EDIT_FLIGHT: 'WITH_FLIGHT_CREATOR', //protected against users that cannot edit a flight
	WITH_USER_ROLE: 'WITH_USER_ROLE', // protected against users that don't have the user role or higher
	WITHOUT_USER_ROLE: 'WITHOUT_USER_ROLE', // protected against users that have the user role or higher
};

const ALL_ROUTES = {
	HOME: {
		path: '/home',
		exact: true,
		title: 'Home',
		guards: [GUARD_TYPES.WITH_AUTH, GUARD_TYPES.WITH_USER_ROLE],
		component: Home,
		icon: HomeIcon,
	},
	LOGIN: {
		path: '/login',
		title: 'Flight Check',
		guards: [GUARD_TYPES.WITHOUT_AUTH],
		component: Login,
	},
	JOBS: {
		path: '/jobs',
		title: 'Jobs',
		exact: true,
		guards: [GUARD_TYPES.WITH_AUTH, GUARD_TYPES.WITH_USER_ROLE],
		icon: Folder,
		component: JobsPage,
		_subRoutes: {
			NEW_JOB: {
				path: '/new-job',
				title: 'New Job',
				exact: true,
				component: NewJobPage,
			},
			JOB: {
				path: '/:jobId',
				title: 'Job',
				component: JobPage,
				_subRoutes: {
					EDIT_JOB: {
						path: '/edit',
						title: 'Edit',
						component: EditJobPage,
					},
					NEW_FLIGHT: {
						path: '/new-flight',
						title: 'New Flight',
						component: NewFlightPage,
					},
					FLIGHT: {
						title: 'Flight',
						component: FlightPage,
						path: '/flight/:flightId',
						_subRoutes: {
							EDIT_FLIGHT: {
								path: '/edit',
								title: 'Edit Flight',
								component: EditFlightPage,
								guards: [GUARD_TYPES.CAN_EDIT_FLIGHT],
							},
							REVIEW_FLIGHT: {
								path: '/review',
								title: 'Review Submitted Flight',
								component: ReviewFlightPage,
								guards: [GUARD_TYPES.CAN_REVIEW_FLIGHT],
							},
						},
					},
				},
			},
		},
	},
	FLIGHTS: {
		path: '/flights',
		title: 'All Flights',
		icon: FlightIcon,
		guards: [GUARD_TYPES.WITH_AUTH, GUARD_TYPES.WITH_USER_ROLE],
		component: FlightsPage,
	},
	MY_PROFILE: {
		path: '/profile',
		title: 'My Profile',
		icon: AccountCircleIcon,
		guards: [GUARD_TYPES.WITH_AUTH, GUARD_TYPES.WITH_USER_ROLE],
		component: ProfilePage,
	},
	USERS: {
		path: '/users',
		title: 'Users',
		icon: PeopleAltIcon,
		guards: [GUARD_TYPES.WITH_AUTH, GUARD_TYPES.WITH_USER_ROLE],
		component: UsersPage,
	},
	PAGE_NOT_FOUND: {
		path: '/404',
		title: 'Page Not Found',
		component: PageNotFound,
	},
	PENDING: {
		path: '/permissions-pending',
		title: 'Flight Check',
		component: PendingUser,
		guards: [GUARD_TYPES.WITH_AUTH, GUARD_TYPES.WITHOUT_USER_ROLE],
	},
};

export const NAV_ROUTES = {
	LOGGED_IN_DRAWER: [
		[ALL_ROUTES.HOME, ALL_ROUTES.JOBS, ALL_ROUTES.FLIGHTS],
		[ALL_ROUTES.USERS],
		[ALL_ROUTES.MY_PROFILE],
	],
	FOOTER: [],
};

// this is where the rules for what and how props of parent routes are inherited by _subRoutes
const _inheritProps = (CHILD, PARENT) => {
	// append path to parent path
	CHILD.path = PARENT.path + CHILD.path;
	// inherit guards
	if (CHILD.guards && PARENT.guards) CHILD.guards.concat(PARENT.guards);
	else if (PARENT.guards) CHILD.guards = PARENT.guards;
	//inherit exact
	if (PARENT.exact && !CHILD.exact) CHILD.exact = PARENT.exact;

	return CHILD;
};

// recursive algorithm that flattens all subroutes any number of layers deep and provides inheritance from parents to _subRoutes
const _flattenRoutes = (ROUTES) => {
	Object.keys(ROUTES).forEach((KEY) => {
		if (ROUTES[KEY]._subRoutes) {
			ROUTES[KEY]._subRoutes = _flattenRoutes(ROUTES[KEY]._subRoutes);
			Object.keys(ROUTES[KEY]._subRoutes).forEach((SUBKEY) => {
				ROUTES[KEY]._subRoutes[SUBKEY] = _inheritProps(
					ROUTES[KEY]._subRoutes[SUBKEY],
					ROUTES[KEY]
				);
				if (ROUTES[SUBKEY]) {
					throw console.error(
						'DUPLICATE ROUTE KEYS: the key for a flattened subroute cannot equal any other route or subroute anywhere'
					);
				}
				ROUTES[SUBKEY] = ROUTES[KEY]._subRoutes[SUBKEY];
			});
			delete ROUTES[KEY]._subRoutes;
		}
	});
	return ROUTES;
};

// Object used to actually generate routes for router
export const FLAT_ROUTES = _flattenRoutes(ALL_ROUTES);

export const DEFAULT_PATHS = {
	ROOT_PAGE: ALL_ROUTES.HOME.path,
	FAILS_GUARD: {
		[GUARD_TYPES.WITH_AUTH]: FLAT_ROUTES.LOGIN.path,
		[GUARD_TYPES.WITHOUT_AUTH]: FLAT_ROUTES.HOME.path,
		[GUARD_TYPES.CAN_REVIEW_FLIGHT]: FLAT_ROUTES.FLIGHT.path,
		[GUARD_TYPES.CAN_EDIT_FLIGHT]: FLAT_ROUTES.FLIGHT.path,
		[GUARD_TYPES.WITH_USER_ROLE]: FLAT_ROUTES.PENDING.path,
		[GUARD_TYPES.WITHOUT_USER_ROLE]: FLAT_ROUTES.HOME.path,
	},
	PAGE_NOT_FOUND: ALL_ROUTES.PAGE_NOT_FOUND.path,
};
