import { ACTION_TYPES as ALERT_ACTIONS } from './alertsReducer';

export const ACTION_TYPES = {
	...ALERT_ACTIONS,
	SET_NEW_VERSION: '_VERSION_SYNC_SET_NEW_VERSION',
};

const initialState = {
	newVersionAvailable: false,
	newVersionBuild: null,
	newVersionSemVer: null,
};

export default function authReducer(state = initialState, action) {
	switch (action.type) {
		case ACTION_TYPES.SET_NEW_VERSION:
			return {
				...state,
				newVersionAvailable: action.payload.newVersionAvailable,
				newVersionBuild: action.payload.newVersionBuild,
				newVersionSemVer: action.payload.newVersionSemVer,
			};
		default:
			return state;
	}
}
