import pick from 'lodash.pick';
import cloneDeep from 'lodash.clonedeep';
import { FLIGHT_FIELDS_FLAT, GROUP_FIELDS } from '../config/flightDataModel';
import { displayFromDbKey } from 'utils/flightConstants';

// these are the final fields used for headers and sorting
const replacers = {
	job: {
		notes: 'jobNotes',
		id: 'jobId',
	},
	flight: {
		notes: 'flightNotes',
		budget: 'mrxGross',
		id: 'flightId',
		lastUpdateAt: 'flightLastUpdateAt',
		createdBy: 'flightCreatedBy',
		otherPlatformDescription: 'platformDetails',
		statusFlag: 'status',
	},
};

const _replaceSingle = (field, ofType) => {
	if (replacers[ofType][field]) return replacers[ofType][field];
	else return field;
};

const _replaceFieldNames = (obj, objType) => {
	Object.keys(replacers[objType]).forEach((field) => {
		if (obj[field]) {
			obj[replacers[objType][field]] = obj[field];
			delete obj[field];
		}
	});
	return obj;
};

// use this to specify fields to frontload in the headers in a specific order
const frontLoadHeaders = [
	'status',
	'campaignCode',
	'jobNumber',
	'jobId',
	'accountName',
	'businessUnit',
	'campaignName',
	'jobNotes',
	'platform',
	'channel',
	'startDate',
	'endDate',
	'mrxGross',
	'platformDetails',
	'creativeUnit',
	'flightNotes',
	'flightLastUpdateAt',
	'flightCreatedBy',
];

const hideFields = {
	job: ['analytics', 'watchers'],
	flight: ['watchers'],
};

//use this to include fields in the headers that aren't in the form data model, mixed in alphabetically instead of frontloaded
const addFieldsToModel = {
	job: [],
	flight: ['flightId'],
};

const _generateHeaders = () => {
	//  clone the fields in the data model to avoid transforming the source
	const clonedGroupFields = cloneDeep(GROUP_FIELDS);
	const clonedFlightFields = cloneDeep(FLIGHT_FIELDS_FLAT);
	// get array of fields from job and flight objects, replacing field names and hiding values
	const jobFields = Object.values(clonedGroupFields)
		.map((obj) => _replaceSingle(obj.id, 'job'))
		.filter((field) => !hideFields['job'].includes(field));
	const flightFields = Object.values(clonedFlightFields)
		.map((obj) => _replaceSingle(obj.id, 'flight'))
		.filter((field) => !hideFields['flight'].includes(field));
	// add fields to the model to be sorted alphabetically if they are not already there
	jobFields.push(
		...addFieldsToModel['job'].filter((field) => !jobFields.includes(field))
	);
	flightFields.push(
		...addFieldsToModel['flight'].filter(
			(field) => !flightFields.includes(field)
		)
	);
	// add the job and flight fields to the headers array if they are not included in the manualHeaders array
	const sortedHeaders = [];
	sortedHeaders.push(
		...jobFields.filter((field) => !frontLoadHeaders.includes(field))
	);
	sortedHeaders.push(
		...flightFields.filter((field) => !frontLoadHeaders.includes(field))
	);
	// sort the non-manual headers alphabetically
	sortedHeaders.sort();
	// return a new array leading with the frontloaded headers
	const headers = [...frontLoadHeaders];
	headers.push(...sortedHeaders);
	return headers;
};

const _dateFields = [
	'startDate',
	'endDate',
	'createdAt',
	'lastUpdateAt',
	'flightLastUpdateAt',
];
const _convertDate = (date) => new Date(date).toLocaleString();

const _cleanData = (data) => {
	// convert array to string
	if (Array.isArray(data)) data = data.join(', ');
	//remove unwanted characters
	if (
		['"', ',', '\n', '\r'].some((token) => {
			return new RegExp(token, 'ig').test(data);
		})
	) {
		data = `"${data.replace(/"/gi, '""')}"`;
	}
	return data;
};

const _transformObjData = (obj, objType) => {
	obj = _replaceFieldNames(obj, objType);
	Object.keys(obj).forEach((key) => {
		if (_dateFields.includes(key)) obj[key] = _convertDate(obj[key]);
		if (key === 'status') obj[key] = displayFromDbKey(obj[key]);
		obj[key] = _cleanData(obj[key]);
	});
	return obj;
};

const filterOutHidden = (obj, type) => {
	return pick(
		obj,
		Object.keys(obj).filter((k) => !hideFields[type].includes(k))
	);
};

export function getCSV(jobFlightsObjArr, withEnconding = true) {
	const headers = _generateHeaders();
	//const headersNoReplace = getOriginals(headers);
	let fullRows = jobFlightsObjArr.map(({ job, flight }) => ({
		job: filterOutHidden(job, 'job'),
		flight: filterOutHidden(flight, 'flight'),
	}));
	// replace the names of fields and clean/convert data
	fullRows = fullRows.map(({ job, flight }) => ({
		..._transformObjData(job, 'job'),
		..._transformObjData(flight, 'flight'),
	}));
	// create the rows of data
	const rows = fullRows.map((jobAndFlightObj) => {
		return headers.map((h) => {
			if (jobAndFlightObj[h]) return jobAndFlightObj[h];
			else return '';
		});
	});
	// generate the CSV
	let csv = '';
	if (withEnconding) csv += 'data:text/csv;charset=utf-8,';
	csv += headers.join(',') + '\n';
	csv += rows.map((r) => r.join(',')).join('\n');

	return csv;
}
