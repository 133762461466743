import React from 'react';
import { connect } from 'react-redux';
import { Container, Grid, Button } from '@material-ui/core';
import { doLogout } from '../../../redux/actions/authActions';

const getVersionString = () => {
	let { BUILD, SEMVER, ENVIRONMENT } = window._env_;
	// eslint-disable-next-line
	if (BUILD === '${build_number}') BUILD = 'no-build';
	// eslint-disable-next-line
	if (ENVIRONMENT === '${environment}') ENVIRONMENT = 'Development';
	else ENVIRONMENT = ENVIRONMENT.charAt(0).toUpperCase() + ENVIRONMENT.slice(1);
	return `${SEMVER} (${BUILD}) ${ENVIRONMENT}`;
};

export function ProfilePage(props) {
	const handleLogOut = (event) => {
		event.preventDefault();
		props.doLogout();
	};
	return (
		<>
			<Container style={{ flexGrow: 1 }} data-test='profile-root'>
				<Grid
					container
					direction='column'
					alignItems='center'
					justify='center'
					style={{ paddingTop: '100px' }}
					spacing={3}
				>
					<Grid item>Flight Check v{getVersionString()}</Grid>
					<Grid item>{props.profile.displayName || ''}</Grid>
					<Grid item>{props.profile.email || ''}</Grid>
					<Grid item>
						<Button
							data-test='logout-button'
							color='primary'
							variant='contained'
							onClick={handleLogOut}
						>
							Log Out
						</Button>
					</Grid>
				</Grid>
			</Container>
		</>
	);
}

const mapStateToProps = (state) => ({
	profile: state.firebase.profile,
});

const mapDispatchToProps = (dispatch) => ({
	doLogout: () => dispatch(doLogout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage);
