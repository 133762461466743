import { ACTION_TYPES } from '../reducers/activityReducer';

const QUEUE_LEN_LIMIT = 20;

const _handleExclusiveQueue = (queue, id) => {
	const idx = queue.indexOf(id);
	if (idx > -1) queue.splice(idx, 1);
	queue.unshift(id);
	return queue.slice(0, QUEUE_LEN_LIMIT);
};

export function updateDocumentsRead(documentsType, id) {
	return async (dispatch, getState, getFirebase) => {
		const uid = getState().firebase.auth.uid;
		const lastDocId = getState().activity.lastDocRead[documentsType];
		// don't bother running this action if the user isn't logged in or if the last updated activity won't change anything
		if (!uid || lastDocId === id) return;

		dispatch({
			type: ACTION_TYPES.SET_LAST_DOC_READ,
			payload: { docType: documentsType, id: id },
		});

		const db = getFirebase().firestore();

		const currentActivityRef = db.doc(`users/${uid}/activity/documentsRead`);

		await db.runTransaction(async (transaction) => {
			let read;
			const current = await transaction.get(currentActivityRef);
			if (current.exists && Array.isArray(current.data()[documentsType])) {
				read = _handleExclusiveQueue(current.data()[documentsType], id);
			} else {
				read = [id];
			}
			await transaction.set(
				currentActivityRef,
				{ [documentsType]: read },
				{ merge: true }
			);
		});
	};
}
