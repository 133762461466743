export const ACTION_TYPES = {
	SET_CLIENT: '_ALGOLIA_SET_CLIENT',
	SET_SEARCH_RESULTS: '_ALGOLIA_SET_SEARCH_RESULTS',
	CLEAR_RESULTS: '_ALGOLIA_CLEAR_SEARCH_RESULTS',
	SET_LOADING: '_ALGOLIA_SET_LOADING',
};

const initialState = {
	searchClient: null,
	searchResults: {},
	loading: false,
};

export default function alertsReducer(state = initialState, action) {
	switch (action.type) {
		case ACTION_TYPES.SET_CLIENT:
			return {
				...state,
				searchClient: action.payload,
			};
		case ACTION_TYPES.SET_SEARCH_RESULTS:
			return {
				...state,
				searchResults: {
					...state.searchResults,
					[action.payload.for]: action.payload.result,
				},
			};
		case ACTION_TYPES.CLEAR_RESULTS:
			return {
				...state,
				searchResults: {},
			};
		case ACTION_TYPES.SET_LOADING:
			return {
				...state,
				loading: action.payload,
			};
		default:
			return state;
	}
}
