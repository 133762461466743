import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import FlightForm from '../../organisms/flightForm';
import JobHeader from '../../molecules/jobHeader';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';
import queryString from 'query-string';

const useStyles = makeStyles((theme) => ({
	grid: {
		marginTop: theme.spacing(3),
	},
}));

const getCloneQuery = (search) => queryString.parse(search).clone;

const NewFlightPage = (props) => {
	const classes = useStyles();
	const { flights, job } = props;
	const cloneId = getCloneQuery(props.location.search);

	if (!job || (cloneId && !flights)) return 'Loading...';

	const cloneFlight = cloneId
		? flights.find((elem) => elem.id === cloneId)
		: null;

	if (cloneId && !cloneFlight) return 'Loading...';

	return (
		<>
			<JobHeader data={job} />
			<Grid container justify='center' className={classes.grid}>
				<FlightForm jobObj={job} remoteData={cloneFlight} cloned={!!cloneId} />
			</Grid>
		</>
	);
};

const mapStateToProps = ({ firestore: { data, ordered } }, ownProps) => ({
	job: data[`openJob[${ownProps.match.params.jobId}]`],
	flights: ordered[`openFlights[${ownProps.match.params.jobId}]`],
});

export default compose(
	firestoreConnect((ownProps) => [
		{
			collection: `jobs`,
			doc: `${ownProps.match.params.jobId}`,
			storeAs: `openJob[${ownProps.match.params.jobId}]`,
		},
		{
			collection: `jobs/${ownProps.match.params.jobId}/flights`,
			storeAs: `openFlights[${ownProps.match.params.jobId}]`,
		},
	]),
	connect(mapStateToProps)
)(NewFlightPage);
