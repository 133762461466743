import React from 'react';
import { Pagination } from '@material-ui/lab';
import { makeStyles, Grid } from '@material-ui/core';

const useStyles = makeStyles({
	paginator: {
		marginTop: '30px',
	},
});

const Paginator = (props) => {
	const classes = useStyles();

	const handleChange = (event, value) => {
		props.changeCallback(value);
	};

	return (
		<div id='paginatorContent'>
			<div>{props.children}</div>
			{props.count > 1 && (
				<Grid container justify='center'>
					<Grid item>
						<Pagination
							className={classes.paginator}
							onChange={handleChange}
							count={props.count}
							page={props.page}
							color='primary'
						/>
					</Grid>
				</Grid>
			)}
		</div>
	);
};

export default Paginator;
