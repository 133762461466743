import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import MediaBuyFormField from 'components/atoms/mediaBuyFormField';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction='up' ref={ref} {...props} />;
});

const ConfirmationDialog = (props) => {
	const {
		open,
		confirmCallback,
		cancelCallback,
		content,
		confirmText,
		confirmInput,
		flightObjArr,
	} = props;

	const [inputValue, setInputValue] = useState(
		(confirmInput && confirmInput.value) || ''
	);

	const handleConfirm = (e) => {
		if (confirmInput) {
			flightObjArr.forEach((flightObj) => {
				flightObj[confirmInput.id] = inputValue;
			});
		}
		confirmCallback(e);
	};

	const handleInputChange = ({ target }) => {
		setInputValue(target.value);
	};

	return (
		<div>
			<Dialog
				open={open}
				onClose={cancelCallback}
				TransitionComponent={Transition}
				aria-labelledby='alert-dialog-slide-title'
				aria-describedby='alert-dialog-slide-description'
			>
				<DialogTitle id='alert-dialog-slide-title'>
					{'Confirm Action'}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id='alert-dialog-slide-description'>
						{content}
					</DialogContentText>
				</DialogContent>
				{confirmInput && (
					<DialogActions>
						<MediaBuyFormField
							field={confirmInput}
							parentField={{ id: null }}
							handleChange={handleInputChange}
							fieldValue={inputValue}
							realTimeTextPush={true}
							fullwidth
						/>
					</DialogActions>
				)}
				<DialogActions>
					<Button onClick={cancelCallback}>NEVERMIND</Button>
					<Button
						onClick={handleConfirm}
						variant='contained'
						color='secondary'
						disabled={confirmInput && confirmInput.required && !inputValue}
					>
						{confirmText}
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default ConfirmationDialog;
