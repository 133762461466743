import React, { useEffect, useState } from 'react';
import {
	IconButton,
	Menu,
	makeStyles,
	List,
	ListItem,
	ListSubheader,
	Button,
	Divider,
	Grid,
	Typography,
} from '@material-ui/core';
import NotificationItem from '../NotificationItem';
import { connect } from 'react-redux';
import {
	getMoreNotifications,
	setShouldReset,
	readNotifications,
} from 'redux/actions/notificationActions';
import SyncIcon from '@material-ui/icons/Sync';

const useStyles = makeStyles((theme) => ({
	menu: {
		width: '500px',
	},
	listSubHeader: {
		backgroundColor: theme.palette.background.paper,
	},
}));

const DefaultWrapper = ({ children }) => <div>{children}</div>;
const MenuWrapper = ({ children, ...menuProps }) => (
	<Menu
		{...menuProps}
		elevation={3}
		anchorOrigin={{
			vertical: 'top',
			horizontal: 'center',
		}}
		transformOrigin={{
			vertical: 'top',
			horizontal: 'center',
		}}
	>
		{children}
	</Menu>
);

const NotificationFeed = (props) => {
	const classes = useStyles();
	const {
		variant,
		open,
		profile,
		notificationFeed,
		getMoreNotifications,
		anchorEl,
		handleClose,
		setShouldReset,
		readNotifications,
	} = props;
	const { shouldReset, listNotifications, noMore } = notificationFeed;
	const { unreadNotifications } = profile;
	const [loading, setLoading] = useState(false);
	const [readingAll, setReadingAll] = useState(false);
	const [lastScroll, setLastScroll] = useState(null);

	const handleGetMore = (reset = false) => {
		if (loading) return;
		setLoading(true);
		getMoreNotifications(reset).then(() => {
			setLoading(false);
		});
	};

	const handleReadAll = () => {
		const ids = listNotifications
			.filter((n) => !n.read)
			.map((notification) => {
				if (notification.id) return notification.id;
				const goto = new URL(notification.url);
				return goto.searchParams.get('notification');
			});
		setReadingAll(true);
		readNotifications(ids).then(() => {
			handleGetMore(true);
			setReadingAll(false);
		});
	};

	useEffect(() => {
		setShouldReset(true);
	}, [unreadNotifications, setShouldReset]);

	useEffect(() => {
		if (variant !== 'menu' || open) {
			handleGetMore(true);
		}
		// eslint-disable-next-line
	}, [variant, open]);

	const handleScroll = ({ target }) => {
		if (loading || noMore) return;
		setLastScroll(target.scrollTop);
		if (!lastScroll) return;
		const direction = target.scrollTop >= lastScroll ? 'down' : 'up';
		const triggeredBottom =
			target.scrollHeight - target.scrollTop <= target.clientHeight + 150 &&
			direction === 'down';
		const triggeredTop =
			target.scrollTop < 50 && shouldReset && direction === 'up';
		if (!triggeredBottom && !triggeredTop) return;
		handleGetMore(triggeredTop);
	};

	let FeedWrapper;
	let ItemWrapper;
	switch (variant) {
		case 'menu':
			ItemWrapper = ListItem;
			FeedWrapper = MenuWrapper;
			break;
		default:
			ItemWrapper = DefaultWrapper;
			FeedWrapper = DefaultWrapper;
			break;
	}

	return (
		<FeedWrapper
			open={open}
			anchorEl={anchorEl}
			onClose={handleClose}
			onScroll={handleScroll}
		>
			<List className={classes.menu}>
				<ListSubheader className={classes.listSubHeader}>
					<Grid container justify='space-between' alignItems='center'>
						<Grid container item alignItems='center' xs={6}>
							<Grid item>
								<Typography variant='h5'>
									{`${unreadNotifications || 0} Unread`}
								</Typography>
							</Grid>
							{shouldReset && (
								<IconButton
									color='secondary'
									variant='outlined'
									onClick={() => handleGetMore(true)}
								>
									<SyncIcon />
								</IconButton>
							)}
						</Grid>
						<Grid container item xs={6} justify='flex-end'>
							<Grid item>
								<Button
									color='primary'
									variant='contained'
									onClick={handleReadAll}
									disabled={
										readingAll || !listNotifications.some((n) => !n.read)
									}
								>
									Read All
								</Button>
							</Grid>
						</Grid>
					</Grid>
					<Divider />
				</ListSubheader>
				{listNotifications.map((notification, idx) => (
					<ItemWrapper key={idx}>
						<NotificationItem
							handleCloseMenu={handleClose}
							notification={notification}
						/>
					</ItemWrapper>
				))}
				{loading && (
					<ItemWrapper>
						<Typography align='center' variant='h6'>
							Loading...
						</Typography>
					</ItemWrapper>
				)}
				{noMore && (
					<ItemWrapper>
						<Typography align='center' variant='h6'>
							No additional notifications.
						</Typography>
					</ItemWrapper>
				)}
			</List>
		</FeedWrapper>
	);
};

const mapStateToProps = ({ firebase, notificationFeed }) => ({
	profile: firebase.profile,
	notificationFeed,
});

const mapDispatchToProps = (dispatch) => ({
	getMoreNotifications: (shouldReset = false, count = 10) =>
		dispatch(getMoreNotifications(shouldReset, count)),
	setShouldReset: (shouldReset) => dispatch(setShouldReset(shouldReset)),
	readNotifications: (ids) => dispatch(readNotifications(ids)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationFeed);
